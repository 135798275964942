
import { Component, Vue } from 'vue-property-decorator'
import icons from 'vuetify/src/services/icons/presets/fa'
import Alert from '@/components/ui/Alert.vue'
import VerificationMethodInfoCard from '@/components/checkout/verification/VerificationMethodInfoCard.vue'
import Button from '@/components/ui/Button.vue'

@Component({
  methods: {
    icons() {
      return icons
    }
  },
  components: { Alert, VerificationMethodInfoCard, Button }
})
export default class VerificationInfoDialog extends Vue {
}
