




































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Button extends Vue {
  @Prop() private text!: string;
  @Prop() private href!: string;
  @Prop() private id!: string;
  @Prop() private target!: string | undefined;
  @Prop({ type: Boolean }) private cta: boolean | undefined;
  @Prop({ type: Boolean }) private primary: boolean | undefined;
  @Prop({ type: Boolean }) private disabled: boolean | undefined;
  @Prop({ type: Boolean }) private small!: boolean;
  @Prop() private icon!: string;
  @Prop() private iconSrc!: string;
  @Prop() private iconPos!: string;

  get classes() {
    return [
      'btn',
      this.cta ? 'cta' : (this.primary ? 'primary' : 'secondary'),
      this.small ? '' : 'v-size--large',
      this.disabled ? 'btn-not-show' : ''
    ]
  }

  handleClick(handleClick: boolean) {
    this.$emit('click', handleClick)
  }
}
