import { render, staticRenderFns } from "./DashboardServiceTile.vue?vue&type=template&id=089ce18a&scoped=true&"
import script from "./DashboardServiceTile.vue?vue&type=script&lang=ts&"
export * from "./DashboardServiceTile.vue?vue&type=script&lang=ts&"
import style0 from "./DashboardServiceTile.vue?vue&type=style&index=0&id=089ce18a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "089ce18a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VRow})
