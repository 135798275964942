YUI.add('smst-session-status', function(Y) {
	"use strict";

	Y.namespace('smst').SessionStatus = Y.Base.create('SessionStatus',Y.Model, [Y.ModelSync.REST], {

		root: '/api/session-status',

		// offline/online status and error messages are not stored on the server.
		// We have to remove it before syncing our state back.
		serialize: function () {
			var json = this.toJSON();
			delete json.offline;
			delete json.msg;
			return Y.JSON.stringify(json);
		}

	},{
		ATTRS : {
			anonymous : {},
			creationTime : { },
			idleSince : { },
			msg : { },
			offline : {
				value: false,
				validator: Y.Lang.isBoolean
			}
		}
	});


	Y.namespace('smst').SessionStatusView = Y.Base.create('SessionStatusView', Y.HandlebarsView, [], {

		template: 'header-status',

        controller: undefined, // set by controller

		events : {
			'#ui-session-status-msg-hide' : { click : '_removeMsg' }
		},

		initializer: function() {
			var that = this,
				model = that.get('model');
			model.after('msgChange', that.render, that);
		},

		render: function() {
			var that = this,
				model = that.get('model'),
				container = that.get('container'),
				messageContainer;

			// before we render the content we have to clear out any old one to remove possible node stalker plugins.
			container.empty();

			// call the default render method of our super class
			that._render(model.toJSON());

			// check if the container is part of the DOM.
			// if not - swap the static part out and replace it with our container.
			if( !container.inDoc() ) {
				var staticNode = Y.one('#ui-app-system-state');
				if( staticNode ) {
					staticNode.replace(container).destroy();
				}
			}

			// check if we have a message
			messageContainer = container.one('#ui-session-status-msg');
			if( messageContainer ) {
				//messageContainer.plug(Y.Plugin.NodeStalker);
			}
		},

		_removeMsg : function(e) {
			var container = this.get('container'),
				msg = container.one('#ui-session-status-msg');

			e.halt();

			if( msg ) {
				msg.remove(true);
			}
		},

        _setActive : function (e) {
           if (this.controller) {
               this.controller._active();
           }
        }

	}, {

		ATTRS : {
			container : {
				valueFn : function() {
					return Y.Node.create('<div id="ui-app-system-state"></div>');
				}
			}
		}
	});

	Y.namespace('smst').SessionStatusController = Y.Base.create('SessionStatusController', Y.Base, [], {

		_ping : null,
		errorCounter : 0,

		_checkError: function(error) {
 			var status = this.get('session-status'),
			// preserve the current message - if there is any.
				msg = status.get('msg');

			if( error ) {
                //console.log("Error: " + error.code);

				msg = this.get('messages.' + error.code);
				if(!msg) {
					msg = this.get('messages.default');
				}
				// When we get an 403 we have to stop pinging the server…
				if( 403 === error.code) {
					this._stop();
				}
				else if(0 === error.code) {
                    //retry two times
                    if (this.errorCounter < 2) {
                        this.errorCounter++;

                        console.log("Error counter (retry): " + this.errorCounter);

                        return;
                    }
                }
			}

            this.errorCounter = 0;

			status.setAttrs( {
				'offline': null != error,
				'msg': msg
			});
		},

		initializer : function() {
			var that = this,
				sessionStatus = new Y.smst.SessionStatus(),
                view = new Y.smst.SessionStatusView({model:sessionStatus});

			that.set('view', view);
            view.controller = that; // we make ourselve known to the view
			that.set('session-status', sessionStatus);

			sessionStatus.load(function(){
				that._startIdleTracker();
				that._startPing();
				that._checkError.apply(that,arguments);
			});
		},

		render: function() {
			this.get('view').render();
		},

		_stop: function () {
			this._ping.cancel();
			Y.IdleTimer.stop();
		},

		destructor : function() {
			this._stop();
		},

		_startPing: function() {
			var that = this;
			// I would love to use WebWorkers here - but we have to support IE8…
			that._ping = Y.later(30*1000, that, function () {
				this.get('session-status').load(Y.rbind(that._checkError, that));
			}, null, true);

		},

		_startIdleTracker: function() {
			var that = this;

			Y.IdleTimer.on("idle", that._idle, that);
			Y.IdleTimer.on("active", that._active, that);
			//start the timer with a default timeout of 30s
			Y.IdleTimer.start();
			Y.log("Timer started...", 'INFO', 'SessionStatusController');

		},

		_idle: function() {
			var that = this,
				session = that.get('session-status');
			session.set('idleSince', new Date().getTime());
			session.save();

		},

		_active: function() {
			var that = this,
				session = that.get('session-status');
			session.set('idleSince', 0);
			session.save(null,function(err,res){ });
		}


	}, {
		ATTRS : {
			'session-status' : { },
			'view' : { },
			'messages' : {
				value : {
					0   : 'Die Verbindung zum Server konnte nicht hergestellt werden. Wir versuchen es noch einmal…',
					403 : 'Die Sitzung ist abgelaufen. Bitte lade die Seite neu.',
					404 : 'Der Server wird gerade neu gestartet. Bitte warte, bis Du Dich erneut einloggen kannst.',
					503 : 'Der Server wird gerade neu gestartet. Bitte warte, bis Du Dich erneut einloggen kannst.',
					'default' : 'In der Anwendung ist ein Fehler aufgetreten. Bitte versuche es später erneut.'
				}
			}
		}
	});

}, '1.0.0', { requires:[
	'base-build',
	'gallery-idletimer',
	'gallery-stalker',
	'handlebars-view',
	'io-base',
	'json-stringify',
	'model',
	'model-sync-rest',
	'node'
]});