
import { Vue, Component } from 'vue-property-decorator'
import axios from 'axios'
import general from '@/mixins/filing/general'

@Component({
  mixins: [general],
})
export default class FilingTypes extends Vue {
  private caseTypes: any[] = []
  private showFilingTypes = true

  async created() {
    await this.getTaxCaseTypes()

    if (this.caseTypes.length <= 1) {
      this.showFilingTypes = false
    }
  }

  async getTaxCaseTypes() {
    await axios.get('elster/tax-declaration-types')
      .then(result => {
        this.caseTypes = result.data
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      }).catch(errors => {
        this.$store.dispatch(
          'updateLoadingErrorStatus',
          {
            status: true,
            message: 'Es konnte nicht ermittelt werden, welche Erklärungen mit diesem Steuerfall übermittelt werden.'
          }
        )
        this.$router.replace({ name: 'checkout-error' })
      })
  }
}
