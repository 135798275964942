import {
  CheckoutComponentEvent,
  CheckoutData,
  CheckoutStep, FilingResponse,
  PaymentData,
  VerificationData, VerificationMethod, YuiSections
} from '@/components/checkout/types'
import Vuex from 'vuex'
import axios from 'axios'
import { router } from '@/onse-module'

function updateCheckoutSteps(checkoutSteps: CheckoutStep[], checkoutComponents: any[] | null, currentStep: string) {
  // @ts-ignore
  if (checkoutComponents?.length !== checkoutSteps?.length) {
    if (currentStep !== 'payment-overview') {
      const startIndex = checkoutSteps?.findIndex(
        (element) => element.stepEventName === currentStep)
      const reducedCheckoutSteps = checkoutSteps?.slice(startIndex)
      reducedCheckoutSteps.forEach(
        // eslint-disable-next-line no-return-assign
        (checkoutStep) => checkoutStep.stepNumber = reducedCheckoutSteps.indexOf(checkoutStep) + 1)
      // eslint-disable-next-line no-return-assign
      checkoutSteps = reducedCheckoutSteps || null
    }

    const componentsIndex = checkoutComponents?.findIndex(
      (component: string) => component === currentStep,
    )
    const reducedComponents = checkoutComponents?.slice(componentsIndex)
    checkoutComponents = reducedComponents || null
  }
  // @ts-ignore
  return { checkoutSteps: checkoutSteps, checkoutComponents: checkoutComponents }
}

export const store = new Vuex.Store({
  state: {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    checkoutData: <CheckoutData | null>null,
    loadingStatus: false,
    loadingErrorStatus: { status: false, message: '' },
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    paymentData: <PaymentData | null>null,
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    elsterFilingData: <any | null>undefined,
    checkoutServiceBaseUri: '',
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    verificationData: <VerificationData | null>null,
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    checkoutSteps: <CheckoutStep[] | null>null,
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    checkoutComponents: <string[] | null>null,
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    currentStep: <string | undefined>undefined,
    currentFilingStepIndex: 0,
    showNavigationButtons: true,
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    checkoutComponentEvent: <CheckoutComponentEvent | null>null,
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    filingResponse: <FilingResponse | null>null,
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    yuiSections: <YuiSections | null>null,
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    nextSectionEnabled: false,
    boUser: false,
  },
  getters: {
    getCheckoutComponentEvent(state) {
      return state.checkoutComponentEvent
    },
    getCurrentFilingStepIndex(state) {
      return state.currentFilingStepIndex
    },
    getCurrentComponent(state) {
      return state.checkoutComponents?.shift()
    },
    getFilingResponse(state) {
      return state.filingResponse
    },
    async getCheckoutData(state) {
      return state.checkoutData
    },
    getCheckoutSteps(state) {
      return state.checkoutSteps
    },
    getCheckoutComponents(state) {
      return state.checkoutComponents
    },
    getPaymentData(state) {
      return state.paymentData
    }
  },
  mutations: {
    setPaymentData(state, data) {
      state.paymentData = data
    },
    setElsterFilingData(state, data) {
      state.elsterFilingData = data
    },
    setCheckoutData(state, checkoutData) {
      state.checkoutData = checkoutData
    },
    setCheckoutServiceBaseUri(state, uri) {
      state.checkoutServiceBaseUri = uri
    },
    setVerificationData(state, verificationData) {
      state.verificationData = verificationData
    },
    setCheckoutSteps(state, newCheckoutSteps) {
      state.checkoutSteps = newCheckoutSteps
    },
    setCheckoutComponents(state, checkoutComponents) {
      state.checkoutComponents = checkoutComponents
    },
    setCurrentStep(state, newCurrentStep) {
      state.currentStep = newCurrentStep
    },
    setCheckoutComponentEvent(state, newCheckoutComponentEvent) {
      state.checkoutComponentEvent = newCheckoutComponentEvent
    },
    setCurrentFilingStepIndex(state, currentFilingStepIndex) {
      state.currentFilingStepIndex = currentFilingStepIndex
    },
    setFilingResponse(state, filingResponse) {
      state.filingResponse = filingResponse
    },
    loadingStatus(state, newLoadingStatus) {
      state.loadingStatus = newLoadingStatus
    },
    loadingErrorStatus(state, newLoadingError) {
      state.loadingErrorStatus.status = newLoadingError.status
      state.loadingErrorStatus.message = newLoadingError.message
    },
    setShowNavigationButtons(state, showNavigationButtons) {
      state.showNavigationButtons = showNavigationButtons
    },
    setCheckoutState(state, remainingFreeFilings) {
      if (!state.checkoutComponents?.includes('payment-overview') &&
        !state.checkoutComponents?.includes('checkout-web-component')) {
        state.currentStep = state.checkoutComponents?.[0]
      } else {
        if (remainingFreeFilings <= 0) {
          state.currentStep = 'payment-overview'
        } else {
          state.currentStep = state.checkoutComponents?.[0]
        }
      }
      let updatedSteps: any
      if (state.checkoutSteps !== null && state.currentStep !== undefined) {
        updatedSteps = updateCheckoutSteps(state.checkoutSteps, state.checkoutComponents, state.currentStep)
        state.checkoutSteps = updatedSteps.checkoutSteps
        state.checkoutComponents = updatedSteps.checkoutComponents
      }
      router.replace({ name: state.currentStep })
    },
    setYuiSections(state, newYuiSections) {
      state.yuiSections = newYuiSections
    },
    setNextSectionEnabled(state, nextSectionEnabled) {
      state.nextSectionEnabled = nextSectionEnabled
    },
    setBoUser(state, boUser) {
      state.boUser = boUser
    },
  },
  actions: {
    updateLoadingStatus({ commit }, payload) {
      commit('loadingStatus', payload)
    },
    updateLoadingErrorStatus({ commit }, payload) {
      commit('loadingErrorStatus', payload)
    },
    updatePaymentData({ commit }, payload) {
      commit('setPaymentData', payload)
    },
    updateElsterFilingData({ commit }, payload) {
      commit('setElsterFilingData', payload)
    },
    async updateCheckoutData({ commit }) {
      commit('loadingStatus', true)
      let response
      try {
        response = await axios.get('/api/checkout/initiate-filing')
        commit('loadingErrorStatus', { status: false, message: '' })
        commit('setCheckoutData', response.data)
        commit('setCheckoutState', response.data.remainingFreeFilings)
      } catch (error) {
        commit('loadingStatus', false)
        commit('loadingErrorStatus', { status: true, message: error.message })
        // eslint-disable-next-line no-console
        console.log('Checkout-Service responded with ', error.message)
      }
      commit('loadingStatus', false)
    },
    resetCheckoutData({ commit }) {
      commit('setCheckoutData', null)
    },
    updateCheckoutServiceBaseUri({ commit }, uri) {
      commit('setCheckoutServiceBaseUri', uri)
    },
    updateVerificationData({ commit }, verificationData) {
      verificationData.verificationMethods.forEach(async function (
        verificationMethod: VerificationMethod
      ) {
        if (verificationMethod.name === 'finApi') {
          commit('loadingStatus', true)
          try {
            const response = await axios.get('/api/config')
            commit('loadingErrorStatus', { status: false, message: '' })
            verificationMethod.url =
              response.data.finApiTargetEnvironment
          } catch (error) {
            commit('loadingErrorStatus', { status: true, message: error.message })
            // eslint-disable-next-line no-console
            console.log(
              'Something happened while retrieving finApi configuration'
            )
          }
          commit('loadingStatus', false)
        }
      })
      commit('setVerificationData', verificationData)
    },
    updateCheckoutSteps({ commit }, checkoutSteps) {
      commit('setCheckoutSteps', checkoutSteps)
    },
    updateCheckoutComponents({ commit }, checkoutComponents) {
      commit('setCheckoutComponents', checkoutComponents)
    },
    updateCurrentStep({ commit }, currentStep) {
      try {
        commit('setCurrentStep', currentStep)
      } catch (error) {
      }
    },
    updateShowNavigationButtons({ commit }, showNavigationButtons) {
      commit('setShowNavigationButtons', showNavigationButtons)
    },
    emitCheckoutComponentEvent({ commit }, newCheckoutComponentEvent) {
      commit('setCheckoutComponentEvent', newCheckoutComponentEvent)
    },
    updateCurrentFilingStepIndex({ commit }, currentFilingStepIndex) {
      commit('setCurrentFilingStepIndex', currentFilingStepIndex)
    },
    async elsterFiling({ commit }) {
      commit('loadingStatus', true)
      try {
        const response = await axios.post('/elster/fileCaseWithElster')
        commit('loadingErrorStatus', { status: false, message: '' })
        commit('setFilingResponse', response.data)
      } catch (error) {
        commit('loadingErrorStatus', { status: true, message: error.response?.data?.errorMessage || error.message })
        // eslint-disable-next-line no-console
        console.log('Something happened: ' + error)
      }
      commit('loadingStatus', false)
    },
    updateYuiSections({ commit }, yuiSections) {
      commit('setYuiSections', yuiSections)
    },
    updateNextSectionEnabled({ commit }, enableNextSection) {
      commit('setNextSectionEnabled', enableNextSection)
    }
  }
})
