
import { Component, Prop, Vue } from 'vue-property-decorator'
import Button from '@/components/ui/Button.vue'

@Component({
  components: { Button }
})
export default class CheckoutAppError extends Vue {
  @Prop() private message!: string
  private showMessage = false
}
