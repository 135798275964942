YUI.add('case-editor-header-search-view', function(Y) {

	"use strict";

	Y.CaseEditorHeaderSearchView = Y.Base.create('CaseEditorHeaderSearchView', Y.HandlebarsView, [], {

		template: 'case-editor-header-search',

		events: {
			'#ui-case-editor-search-field' : {
				focus:'_focus',
				blur:'_blur'
			},
			'button' : {
				click: '_fireOpenSearch'
			}
		},

		_inputNode: null,

		_keyUpListener: null,

		initializer: function() {
		},

		_focus: function() {
			// start listening
			this._inputNode = Y.one('#ui-case-editor-search-field');
			this._keyUpListener = this._inputNode.on('key', this._fireOpenSearch, 'enter', this);
		},

		_blur: function() {
			if( this._keyUpListener) {
				this._keyUpListener.detach();
				this._keyUpListener = null;
			}
		},

		_fireOpenSearch : function(e) {
			e.halt();

            var searchField = Y.one('#ui-case-editor-search-field');

            var link = Y.smst.CaseEditor.SECTIONS.getByName('search').get('link'),
                searchTerm = encodeURIComponent(searchField.get('value'));

			if(null == searchTerm || searchTerm.length < 3) {
				this.fire('alert', {msg:'Gib min. 3 Zeichen ein.'});
				return;
			}
			link += '/'+searchTerm;
			this.fire('open', {link:link});
		}

	},{

		ATTRS: {
			container: {
				valueFn: function(){return Y.Node.create('<form id="case-search" onsubmit="return false;"></form>');}
			}
		}
	});

}, '1.0.0', {
	requires:[
		'base-build',
		'event',
		'handlebars-view',
		'node'
	]});
