
import { Component, Prop, Vue } from 'vue-property-decorator'
import FinalImage from '@/components/filing-final/FinalImage.vue'
import FinalTile from '@/components/filing-final/FinalTile.vue'
import axios from 'axios'
import FilingNotCompleted from '@/components/filing-documents/FilingNotCompleted.vue'
import NavigationButtons from '@/components/filing-documents/navigation/NavigationButtons.vue'

@Component({
  components: {
    FilingNotCompleted,
    FinalImage,
    FinalTile,
    NavigationButtons
  }
})
export default class FinalizedFiling extends Vue {
  @Prop() private notYetSentViaElster: any | undefined
  @Prop() private transferDecreeData: any | undefined
  @Prop() private yuiSections: any | undefined

  private showDecreeSection = false
  private nextButtonId = 'ui-go-to-decree'

  private tilesList = [{
    tileType: 'what-next',
    title: 'Wie geht es weiter?',
    icon: '/../img/what_next.svg',
    description: undefined,
    action: undefined,
    url: undefined,
  },
  {
    tileType: 'feedback',
    title: 'Feedback geben',
    icon: '/../img/checkout/give_feedback.svg',
    description: undefined,
    action: undefined,
    url: '',
  },
  {
    tileType: 'last-four-years',
    title: '4x Steuererstattung',
    icon: '/../img/checkout/last_four_years.svg',
    description: 'Jetzt freiwillig abgeben und 4 Jahre rückwirkend Erstattung sichern!',
    action: 'Jetzt loslegen',
    url: 'https://www.smartsteuer.de/online/steuerjahr-waehlen/'
  }
  ]

  created() {
    this.fetchSurveyLinks()
    this.showDecreeSection = this.transferDecreeData
  }

  async fetchSurveyLinks() {
    await axios.get('/api/checkout/survey-link/FILING').then(result => {
      this.tilesList[1].url = result.data
    })
  }
}
