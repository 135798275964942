
import { Component, Prop, Vue } from 'vue-property-decorator'
import Button from '@/components/ui/Button.vue'

@Component({
  components: { Button }
})
export default class NavigationButtons extends Vue {
  @Prop() private nextButtonId: any | undefined

  goBack() {
    window.history.back()
  }
}
