





























import { Component, Prop, Vue } from 'vue-property-decorator'
import Button from '@/components/ui/Button.vue'

@Component(
  {
    components: {
      Button
    }
  }
)
export default class Dialog extends Vue {
  @Prop() private value!: boolean
  @Prop() private title!: string

  get show() {
    return this.value
  }

  set show(value) {
    this.$emit('input', value)
  }
}
