






























































import LoadingFrame from '@/components/frames/LoadingFrame'
import Button from '@/components/ui/Button.vue'
import { Component, Inject, Prop, Vue } from 'vue-property-decorator'
import {
  CaseAnalysisPreviewContent,
  CaseAnalysisPreviewProps,
  CaseAnalysisReport,
  GVError,
  SpreadsheetData
} from '@/components/case-analysis/types'
import axios from 'axios'
import Tabs from '@/components/ui/Tabs.vue'
import Tab from '@/components/ui/Tab.vue'
import Dialog from '@/components/ui/Dialog.vue'
import CaseAnalysisComponent from '@/components/case-analysis/CaseAnalysisComponent.vue'
import PreviewWrapperComponent from '@/components/case-analysis/PreviewWrapperComponent.vue'
import GVErrorComponent from '@/components/case-analysis/GVErrorComponent.vue'

const APPENDIX_PDF_DOWNLOAD_CLASS = 'appendix-pdf-download'
const REPORT_PDF_DOWNLOAD_CLASS = 'report-pdf-download'

@Component({
  components: { Tabs, Tab, Dialog, PreviewWrapperComponent, GVErrorComponent, CaseAnalysisComponent, LoadingFrame, Button }
})
export default class CaseAnalysisApp extends Vue {
  @Prop({ default: () => [] }) private gvErrors!: GVError[]
  @Prop({ default: true }) private smartCheckIsOk!: boolean
  @Prop({ default: () => [] }) private sheets!: SpreadsheetData[]
  @Prop() private previewProps: CaseAnalysisPreviewProps | undefined
  @Prop() private overviewProps: CaseAnalysisPreviewProps | undefined
  @Inject() private eventBus!: Vue
  private api = axios.create()
  private displayPreviewDialog = false
  overviewPreview!: CaseAnalysisPreviewContent
  analysisPreview!: CaseAnalysisPreviewContent

  data() {
    return {
      overviewPreview: { report: null, sheet: null },
      analysisPreview: { report: null, sheet: null }
    }
  }

  get downloadProps() {
    if (this.analysisPreview.report !== null) {
      const report = this.analysisPreview.report
      return {
        cls: REPORT_PDF_DOWNLOAD_CLASS,
        id: report.id,
        type: report.type,
        sessionId: report.sessionId
      }
    }
    if (this.analysisPreview.sheet !== null) {
      return {
        cls: APPENDIX_PDF_DOWNLOAD_CLASS,
        id: this.analysisPreview.sheet.id,
        type: undefined,
        sessionId: undefined
      }
    }
    return {
      cls: '',
      id: undefined,
      type: undefined,
      sessionId: undefined
    }
  }

  async mounted() {
    this.eventBus.$on('selectSheet', (sheet: SpreadsheetData) => {
      this.analysisPreview = { report: null, sheet }
      this.displayPreviewDialog = true
    })
    this.eventBus.$on('selectReport', (report: CaseAnalysisReport) => {
      this.analysisPreview = { report, sheet: null }
      this.displayPreviewDialog = true
    })
    const statusUpdate = this.api.post('/api/evaluation/updateStatus')
    const defaultReport = this.api.get('/api/evaluation/defaultReport')

    await statusUpdate
    const report: CaseAnalysisReport = (await defaultReport).data
    this.overviewPreview = { report, sheet: null }
  }
}
