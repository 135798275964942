
import { Component, Vue } from 'vue-property-decorator'
import Alert from '@/components/ui/Alert.vue'

@Component({
  components: { Alert }
})
export default class FilingProcessingInfo extends Vue {
  created() {
    this.$store.dispatch('updateCurrentStep', 'checkout-filing-info')
  }
}
