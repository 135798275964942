
import { Component, Vue } from 'vue-property-decorator'
import Button from '@/components/ui/Button.vue'

@Component({
  components: {
    Button
  }
})
export default class FinApiInfo extends Vue {
  private showDialog = true

  showInfo(show: boolean) {
    this.showDialog = show
    this.$emit('show-info', show)
  }

  updated() {
    if (!this.showDialog) {
      this.showInfo(this.showDialog)
    }
  }
}
