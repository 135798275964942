YUI.add('smart-routes', function (Y, NAME) {

"use strict";
(function () {

	/**
	 * The will handle all the logics.
	 *
	 * @class RoutesController
	 * @constructor
	 * @cfg {object} configuration attributes
	 */
	Y.RoutesController = Y.Base.create(NAME, Y.View, [], {

		_map : null,
		_directions  :null,
		_fastRouteRenderer : null,
		_shortRouteRenderer: null,

		/**
		 * This will convert a route into a compact collections of some metadata.
		 * @private
		 */
		_createRouteData: function(route){
			var totalDistance = 0,
					totalDuration = 0,
					date = new Date(0),
					minutes,
					i;

			for (i = 0; i < route.legs.length; i++) {
				totalDistance += route.legs[i].distance.value;
				totalDuration += route.legs[i].duration.value;
			}
			totalDistance /= 1000.0;

			date.setUTCSeconds(totalDuration);
			minutes = date.getUTCMinutes();
			minutes = 10 > minutes ? '0' + minutes : '' + minutes;
			return {
				distance: {
					text: Y.DataType.Number.format(totalDistance, {
						thousandsSeparator: '.',
						decimalSeparator: ",",
						decimalPlaces: 2
					}),
					value: totalDistance
				},
				duration: {
					text: Y.Lang.sub('{h}:{m}', {
						h: date.getUTCHours(),
						m: minutes
					}),
					value: totalDuration
				}
			};
		},

		/**
		 * This will initialized all google classes once they are loaded.
		 * @private
		 */
		_createMapObjects: function() {
			var that = this,
				maps = that._googleFrame.google.maps,
				smartsteuer = new maps.LatLng(52.393921,9.789726),
				map;

			// creating a map of germany with some defaults.
			// we will center on our homebase.
			map = that._map = new maps.Map( that._googleFrame.get('domNode'), {
				zoom:7,
				center: smartsteuer,
				mapTypeId: maps.MapTypeId.ROADMAP,
				disableDefaultUI:true,  // this is important for us!
				zoomControl: true

			});

			// create an directions service
			that._directions = new maps.DirectionsService();

			// create two directions renderer one for the fast one for the slow track.
			that._fastRouteRenderer = new maps.DirectionsRenderer({
				draggable: true,
				map: map,
				polylineOptions: {
					strokeColor: '#008F6B',  // success
					strokeOpacity: 0.7,
					strokeWeight: 5
				}
			});
			that._shortRouteRenderer = new maps.DirectionsRenderer({
				draggable: true,
				map: map,
				polylineOptions: {
					strokeColor: '#5F6FFF',  // electric
					strokeOpacity: 0.7,
					strokeWeight: 5
				}
			});

			// Every time one or both of the routes change we have to update the internal state.
			maps.event.addListener(
				that._fastRouteRenderer, 'directions_changed', Y.rbind(function () {
						var renderer = that._fastRouteRenderer,
							index = that._fastRouteIndex || renderer.getRouteIndex();
						delete that._fastRouteIndex;
					that.set('fastRouteData',
							that._createRouteData(renderer.getDirections().routes[index]));
				}, that)
			);
			maps.event.addListener(
				that._shortRouteRenderer, 'directions_changed', Y.rbind(function () {
						var renderer = that._shortRouteRenderer,
							index = that._shortRouteIndex || renderer.getRouteIndex();
						delete that._shortRouteIndex;
						that.set('shortRouteData',
							that._createRouteData(renderer.getDirections().routes[index]));
				}, that)
			);
		},

		/**
		 *
		 * @param result
		 * @private
		 */
		_processRoutesResult: function(result) {
			var that = this,
				routes = result.routes,
				routesMeta = [], i, fast, shortest, fastIdx, shortIndex, cur;



			for( i = 0; i < routes.length; i++) {
				routesMeta.push(that._createRouteData(routes[i]));
			}
			// find fastest and shortest
			for( i = 0; i < routes.length; i++) {
				cur = routesMeta[i];
				if(!fast || fast.duration.value > cur.duration.value ) {
					fast = cur;
					fastIdx = i;
				}
				if(!shortest || shortest.distance.value > cur.distance.value ) {
					shortest = cur;
					shortIndex = i;
				}
			}

			// only add a fast route if there is one.
			if (shortIndex !== fastIdx) {
				that._fastRouteRenderer.setMap(that._map);
				// we will need the index before it is set two rows down.
				// This is only needed for the first directions_change event. After that we can use the index
				// set in the renderer
				that._fastRouteIndex = fastIdx;
				that._fastRouteRenderer.setDirections(result);
				that._fastRouteRenderer.setRouteIndex(fastIdx);
			} else {
				that._fastRouteRenderer.setMap(null);
			}

			// plug them into their renderer
			// we will need the index before it is set two rows down.
			// This is only needed for the first directions_change event. After that we can use the index
			// set in the renderer
			that._shortRouteIndex = shortIndex;
			that._shortRouteRenderer.setDirections(result);
			that._shortRouteRenderer.setRouteIndex(shortIndex);

			// reset any error
			that._set('error', null);
		},


		/**
		 * This will actually trigger google to provide some routes.
		 * @private
		 */
		_updateRoutes: function() {
			var that = this,
				directions = that._directions,
				maps = that._googleFrame.google.maps,
				origin = that.get('origin'),
				destination = that.get('destination');

			if( !origin || !destination ) {
				// we don't have enough data.
				that._set('error', 'Trage zwei vollständige Adressen in die obigen Felder ein.');
				return;
			}
			that._set('error', '…');

			// rest the old routes data
			that.setAttrs({fastRouteData:null,shortRouteData:null});


			directions.route({
				origin:origin,
				destination:destination,
				travelMode: maps.TravelMode.DRIVING,
				provideRouteAlternatives: true
			}, function(result,status){
				if (status === maps.GeocoderStatus.OK) {
					that._processRoutesResult( result );
				} else {
					that._set('error', 'Trage zwei vollständige Adressen in die obigen Felder ein.');
				}
			});

		},

		/**
		 * When ever the origin or the destination changes we have to calculate new routes.
		 */
		_createChangeListener: function() {
			var that = this;

			that._handlerList.push( that.after('originChange', that._updateRoutes, that));
			that._handlerList.push( that.after('destinationChange', that._updateRoutes, that));
		},

		/**
		 * This is loading the google services and marks this controller as ready when everything works as expected.
		 */
		initializer: function() {
			var that = this;

			that._handlerList = [];

			/**
			 * This will fire when the google apis were loaded.
			 */
			that.publish('ready', {
				fireOnce:true,
				preventable:false,
				defaultFn: function(){
					that._set('ready', true);
					that._createMapObjects();
					that._createChangeListener();
					that._updateRoutes();
				},
				context: that
			});

		},

		_renderGoogleMapsFrame: function (container) {
			var that = this;

			that._googleFrame = new Y.GoogleMapsFrame({
				source: 'https://maps.google.com/maps/api/js',
				container: container,
				parameters: {
					language: 'de',
					region: 'DE',
					key: 'AIzaSyCHLNKHZt8sN5LejorwzB-1JdmdAocC_Y8'
				}
			});

			that._googleFrame.once('load', function () {
				that.fire('ready');
			});
		},


		render: function() {
			var that = this,
				container = that.get('container');

			// Append the container element to the DOM if it's not on the page already.
			if (!container.inDoc()) {
				Y.one('body').append(container);
			}
			// only now we can create and load the google frame.
			that._renderGoogleMapsFrame(container);

			// sticking to the best practice and returning our self.
			return this;
		},

		destructor: function() {
			var that = this;
			// sanity check
			if(that._googleFrame) {
				new Y.Node( that._googleFrame.get('domNode') ).remove();
				that._googleFrame.destroy();
			}
			// we must not remove or destroy our container - otherwise google will throw exceptions.
			//
			that._googleFrame = null;
			that._map = null;
			that._fastRouteRenderer = null;
			that._shortRouteRenderer = null;
			that._directions = null;

			// destory the changeListeners!!!
			while(0 < that._handlerList.length) {
				that._handlerList.pop().detach();
			}

		}


}, {
		ATTRS: {

			fastRouteData : {},

			shortRouteData : {},

			destination: {},

			origin: {},

			error: {
				value: null,
				readOnly: true
			},

			/**
			 * This is the container the google frame will be rendered into. It is important to attach
			 * it to the DOM _before_ this view is rendered!
			 */
			container : {
				valueFn: function() {
					return Y.Node.create('<div id="case-editor-interview-tool-routes-map"></div>');
				}
			},
			ready : {
				readOnly: true,
				value: false
			}
		}
	});

})();(function () {

	/**
	 * This will display the goolge maps for a route and handle the route selection.
	 *
	 * @class RoutesWidget
	 * @extends Widget
	 * @constructor
	 * @cfg {object} configuration attributes
	 */
	Y.RoutesWidget = Y.Base.create(NAME, Y.Widget, [Y.WidgetChild], {

		_controller : null,
		_infoNode: null,
		_originNode: null,
		_destinationNode: null,
		_fastRouteInfoNode: null,
		_shortRouteInfoNode: null,
		_alternativeRouteInfoContent: null,
		_loadingMaskNode: null,

		_template: null,
		_infoTemplate: null,

		_handlerList: [],


		_removeLoadingMask: function (e) {
			if (e.newVal && this._loadingMaskNode) {
				this._loadingMaskNode.remove(true);
				this._loadingMaskNode = null;
			}
		},

		_updateInfo: function (e) {
			var info = e.newVal;
			if (null === info) {
				this._infoNode.hide();
			} else {
				this._infoNode.one('h1').setContent(info);
				this._fastRouteInfoNode.hide();
				this._shortRouteInfoNode.hide();
				this._infoNode.show();
			}
		},

		initializer: function () {
			var that = this,
				controller;

			// load the templates we need for our job.
			that._template = Y.HandlebarsTemplates.lookup('tool-routes-form');
			that._infoTemplate = Y.HandlebarsTemplates.lookup('tool-routes-info');

			// create the controller which encapsulates the google apis.
			controller = that._controller = new Y.RoutesController({
				origin:that.get('origin'),
				destination:that.get('destination')
			});
			// listen for important events
			that._handlerList.push(controller.once('readyChange', that._removeLoadingMask, that));
			that._handlerList.push(controller.after('errorChange', that._updateInfo, that));
			that._handlerList.push(controller.after('shortRouteDataChange', that._handleShortRouteChange, that));
			that._handlerList.push(controller.after('fastRouteDataChange', that._handleFastRouteChange, that));
		},

		renderUI: function () {
			// render the template
			var that = this,
				content = Y.Node.create(that._template()),
				box = that.get('contentBox');

			// query all needed nodes
			that._infoNode = content.one('#case-editor-interview-tool-routes-result-loading');

			that._originNode = content.one('#case-editor-interview-tool-routes-input-origin');
			that._destinationNode = content.one('#case-editor-interview-tool-routes-input-destination');

			that._shortRouteInfoNode = content.one('#case-editor-interview-tool-routes-result-short');
			that._fastRouteInfoNode = content.one('#case-editor-interview-tool-routes-result-fast');

			that._loadingMaskNode = content.one('#case-editor-interview-tool-routes-map-placeholder');
			that._formNode = content.one('#case-editor-interview-tool-routes-form');

			// insert the content into the dom
			box.setContent(content);

			// render the controller view
			box.one('#case-editor-interview-tool-routes-map').replace(that._controller.get('container'));
			that._controller.render();

		},

		_copyFieldValue: function (attName, input) {
			this.set(attName, input.get('value'), {src: 'ui'});
		},

		_handleOriginNodeChange: function (e) {
			this._copyFieldValue('origin', e.target);
		},

		_handleOriginChange: function (e) {
			if (e.src && 'ui' === e.src) {
				this._controller.set('origin', e.newVal);
			} else {
				this._originNode.set('value', e.newVal);
			}
		},

		_handleDestinationNodeChange: function (e) {
			this._copyFieldValue('destination', e.target);
		},

		_handleDestinationChange: function (e) {
			if (e.src && 'ui' === e.src) {
				this._controller.set('destination', e.newVal);
			} else {
				this._destinationNode.set('value', e.newVal);
			}
		},

		_handleRouteChange: function(node, title, type, data) {
			if( data ) {
				node.setContent(this._infoTemplate({
					title: title,
					type: type,
					durationText: data.duration.text,
					distanceText: data.distance.text,
					clazz: null
				}));
				node.show();
			} else {
				node.hide();
			}
		},

		_handleFastRouteChange: function (e) {
			this._handleRouteChange(this._fastRouteInfoNode, 'Schnellste', 'fast', e.newVal);
		},

		_handleShortRouteChange: function (e) {
			this._handleRouteChange(this._shortRouteInfoNode, 'Kürzeste', 'short', e.newVal);
		},

		_setDistance: function (e) {
			this.set('distance', this._controller.get(e.target.get('value') + 'Data.distance.value'));
		},

		_handleEnter: function(e) {
			var that = this;
			e.halt();
			that._copyFieldValue('origin', that._originNode);
			that._copyFieldValue('destination', that._destinationNode);
		},

		bindUI: function () {
			var that = this;
			
			// set up some listeners to update route data.
			that._handlerList.push(that._originNode.after('change', that._handleOriginNodeChange, that));
			that._handlerList.push(that.after('originChange', that._handleOriginChange, that));

			that._handlerList.push(that._destinationNode.after('change', that._handleDestinationNodeChange, that));
			that._handlerList.push(that.after('destinationChange', that._handleDestinationChange, that));

			that._handlerList.push(that._formNode.delegate('key', that._handleEnter, 'down:enter', 'input', that));
            that._handlerList.push(that._formNode.delegate('click', that._handleEnter, '#ui-route-update', that));

			that._handlerList.push(that.get('contentBox').delegate('click', that._setDistance, 'button', that));
		},

		syncUI: function () {
			this._originNode.set('value', this.get('origin'));
			this._destinationNode.set('value', this.get('destination'));
		},

		destructor: function () {
			var i = 0;
			for (; i < this._handlerList.length; i++) {
				this._handlerList[i].detach();
			}
			this._handlerList = [];

			this._infoNode = null;
			this._originNode = null;
			this._destinationNode = null;
			this._fastRouteInfoNode = null;
			this._shortRouteInfoNode = null;
			this._alternativeRouteInfoContent = null;
			this._loadingMaskNode = null;

			this._template = null;
			this._infoTemplate = null;

			this._controller.destroy();
		},

		/**
		 * A public interface to trigger a route request
		 */
		start: function() {
			var that = this;
			if( true === that._controller.get('ready')) {
				that._controller.setAttrs({
					origin: that.get('origin'),
					destination: that.get('destination')
				});
				that._controller._updateRoutes();
			}
		}

	}, {
		ATTRS: {
			origin : {
				value: ""
			},
			destination : {
				value: ""
			},
			distance: {}
		}
	});
})();

}, '1.0.0', {
    "requires": [
        "app",
        "arraysort",
        "node",
        "event",
        "widget",
        "widget-child",
        "gallery-google-maps-frame",
        "datatype-number-format",
        "datatype-date",
        "smart-handlebars-templates",
        "transition"
    ]
});
