
import { Component, Prop, Vue } from 'vue-property-decorator'
import Button from '@/components/ui/Button.vue'
import Dialog from '@/components/ui/Dialog.vue'

@Component({
  components: { Button, Dialog }
})
export default class DashboardServiceTile extends Vue {
  @Prop() serviceUrl!: string
  @Prop() tileIcon!: string
  @Prop() iconAltText!: string
  @Prop() dialogTitle!: string
  @Prop() tileText!: string
  @Prop() tileButtonText!: string
  @Prop() tileButtonClass!: string
  @Prop() confirmButtonText!: string

  private dialogOpen = false
}
