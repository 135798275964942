
import { Component, Prop, Vue } from 'vue-property-decorator'
import Dialog from '@/components/ui/Dialog.vue'

@Component({
  components: { Dialog }
})
export default class FinalTile extends Vue {
  @Prop() private tileType: any | undefined
  @Prop() private title: any | undefined
  @Prop() private icon: any | undefined
  @Prop() private description: any | undefined
  @Prop() private action: any | undefined
  @Prop() private url: any | undefined

  private idWhatNext = ''
  private openWhatNextDialog = false

  created() {
    if (this.tileType === 'what-next') {
      this.idWhatNext = this.tileType
    }
  }

  openDialog(show: boolean) {
    this.openWhatNextDialog = show
  }
}
