
import { Component, Vue } from 'vue-property-decorator'
import { VerificationMethod } from '@/components/checkout/types'
import axios from 'axios'
import Alert from '@/components/ui/Alert.vue'
import Button from '@/components/ui/Button.vue'

@Component({
  components: { Alert, Button }
})
export default class WebIDVerification extends Vue {
  private webIDVerificationStarted = false
  private webIDVerificationInProgress = false
  private retryWebIDVerification = false
  private loadNextComponent = false

  startWebIDVerification() {
    this.openInNewTab()
    this.webIDVerificationStarted = true
  }

  openInNewTab() {
    // noinspection TypeScriptValidateTypes
    const webIdUrl = this.$store.state.verificationData?.verificationMethods.find(
      (item: VerificationMethod) => {
        if (
          item.name ===
          this.$store.state.verificationData?.chosenVerificationMethod
        ) {
          return item
        }
      }
    ).url

    window.open(webIdUrl, '_blank', 'noreferrer')
  }

  updated() {
    if (this.webIDVerificationStarted) {
      this.webIDVerificationInProgress = true
    }
  }

  async updateVerificationStatus() {
    let verificationMethod = ''
    await axios.get('/api/checkout/verification-method')
      .then(result => {
        verificationMethod = result.data.verificationMethod
      }).catch(errors => {
        // eslint-disable-next-line no-console
        console.log('Could not fetch verification data: ' + errors)
      })

    if (verificationMethod !== 'UNKNOWN') {
      this.webIDVerificationStarted = false
      this.webIDVerificationInProgress = false
      this.retryWebIDVerification = false
      this.goToFiling()
    } else {
      this.webIDVerificationStarted = false
      this.webIDVerificationInProgress = false
      this.retryWebIDVerification = true
    }
  }

  goToFiling() {
    this.loadNextComponent = true
    const waitingInterval = setInterval(() => {
    }, 1000)

    setTimeout(() => {
      clearInterval(waitingInterval)
      this.$router.replace({ name: 'checkout-filing' })
    }, 5 * 1000)
  }
}
