YUI.add('case-editor-app-interview-page-form', function (Y) {

    "use strict";

    var VERBOSE = false;

    /**
     * This class represents a form or page in the interview.
     *
     * @class OnseBase
     * @extends Base
     * @constructor
     * @cfg {object} configuration attributes
     */
    Y.CaseEditorAppInterviewPageForm =
        Y.Base.create('CaseEditorAppInterviewPageForm', Y.CaseEditorAppInterviewPageView, [Y.AppOverlays], {

            events: {
                '.case-editor-interview-field-buttonpicker-item': {click: '_handleImageListClick'}
            },

            overlays: {},

            // --- lifecycle methods ---------------------------------------------------------------------------------------

            initializer: function () {
                const that = this;
                const handlers = that.get('handlers');
                // set some initial values
                that._smst_modified = false;
                that.fields = {};
                that.__fieldRules = new Y.interview.FieldRules();


                // for easy access we will store the current state object in a class variable.
                that._pageState = that.get('pageState');
                that._pageData = null;

                handlers.push(that.on("interview:showArea", that._afterShowArea, that));

                handlers.push(that.on("interview:hideInlineArea", that._checkDisappearedFields, that));

                handlers.push(that.after('interview:fieldChanged',
                    that._processInlineAreaRulesAfterFieldChanged, that));

                handlers.push(that.after('interview:fieldChanged', function (e) {
                    that.__fieldRules.checkRules(this._pageState, e);
                }, that));

                handlers.push(that.after('*:field-blur', function (e) {
                    that.__fieldRules.checkRules(this._pageState, e);
                }, that));

                handlers.push(Y.on('*:load', that._prepareUnload, that));
                handlers.push(Y.on('*:unload', that._prepareUnload, that));
                handlers.push(Y.on('*:next', that._prepareNext, that));

                that.publish('interview:hideInlineArea', {
                    emitFacade: true,
                    preventable: false
                });

                that.publish("interview:rendering", {
                    emitFacade: true,
                    preventable: false
                });

                that.publish("interview:rendered", {
                    emitFacade: true,
                    preventable: false
                });

                that.publish('interview:showArea', {
                    emitFacade: true,
                    preventable: false
                });

                Y.once('smartsteuer:ready', function (config) {
                    that.smstConfig = config
                }, that);
            },

            destructor: function () {
                this._mobilefielddecorator && this._mobilefielddecorator.destroy() && (this._mobilefielddecorator = null);

                Y.Object.each(this.fields, function (it) {
                    it.destroy();
                }, this);
            },

            _initForm: function () {
                const that = this;

                Y.log("### interview form render");

                const pageData = that._pageData = JSON.parse(that._pageState.interviewPage);
                let page = "";

                const areaTemplateString = (element, areaContent) => {
                    element.content = areaContent
                    element.renderFieldsInTiles = element.checkboxArea;

                    return Y.HandlebarsTemplates.lookup('case-editor-interview-area-area')(
                        {
                            "area": element
                        }
                    );
                }

                const fieldTemplateString = (element) => {
                    const template = "case-editor-interview-field-" + element.interviewFieldType;

                    if (element.interviewFieldType === "imagelist") {

                        let found = false;

                        element.nativeField.list.data.forEach((item, index) => {
                            item.label = element.nativeField.list.rawData[0][index][1];
                            item.label2 = element.nativeField.list.rawData[0][index][4];
                            item.path = element.nativeField.list.rawData[0][index][2];
                            item.value = element.nativeField.list.rawData[0][index][0];

                            if (item.value === element.value) {
                                item.selected = true;
                                found = true;
                            }

                            if (index > 0 && index % 2 === 0) {
                                item.br = true;
                            }
                        });
                    }
                    if (element.interviewFieldType === "select") {

                        element.nativeField.list.data.forEach((item) => {
                            if (item.value === element.value) {
                                item.selected = true;
                            }

                            if (Array.isArray(item.label)) {
                                item.label = item.label[0];
                            } else {
                                item.label = item.label.replace("[\"", "").replace("\"]", "");
                            }
                        });
                    }

                    //TODO: refactor the backend parameter parsing, change interview-fieldrules.js and remove this
                    if (that._pageState.isMultiFieldArea) {
                        element.name = element.name + "[" + that._pageState.multiFieldIndex + "]";
                        //TODO: this is used for shallow / template values and still has to be set, too
                        element.nativeField.mfaIndex = that._pageState.multiFieldIndex;
                    }

                    //render field into element...
                    element.content = Y.HandlebarsTemplates.lookup(template)(
                        {
                            "field": element
                        }
                    );

                    //...add some convenient helpers for handlebars...
                    element.imagelist = element.interviewFieldType === "imagelist";
                    element.checkbox = element.interviewFieldType === "checkbox" || element.interviewFieldType === "togglewidget";
                    element.itemsShallBeRenderedAsTiles = element.parentArea.checkboxArea
                    element.itemsShallBeRenderedAsLines = !element.parentArea.checkboxArea

                    //...and put it into a line which is added to the page
                    return Y.HandlebarsTemplates.lookup('case-editor-interview-area-line')(
                        {
                            "field": element
                        }
                    );
                }


                function processInterviewElement(element) {
                    let content = '';

                    if (element.interviewElementType === 'interviewField') {
                        content += fieldTemplateString(element);
                    }

                    if (element.interviewElementType === 'interviewArea') {
                        let areaContent = '';

                        element.interviewElements.forEach((innerElement) => {
                            innerElement.parentArea = element;
                            areaContent += processInterviewElement(innerElement);
                        });

                        content += areaTemplateString(element, areaContent);
                    }

                    return content;
                }

                pageData.interviewElements.forEach((element) => {
                    page += processInterviewElement(element);
                });


                const content = Y.HandlebarsTemplates.lookup('case-editor-interview-area-form')(
                    {
                        "formIndex": that._pageState.formIndex,
                        "formId": that._pageState.formId !== -1 ? that._pageState.formId : "",
                        "multiFieldAreaIndex": that._pageState.multiAreaMeta.multiFieldAreaIndex,
                        "maxMultiFieldAreaIndex": that._pageState.multiAreaMeta.maxMultiFieldAreaIndex ?
                            that._pageState.multiAreaMeta.maxMultiFieldAreaIndex
                            :
                            0,
                        "content": page
                    }
                );

                that.set('pageContent', content);

                //init inline mapping
                Y.all('.case-editor-interview-area').each(
                    function (it) {
                        if (it.getAttribute('data-parentInlineId')) {
                            if (that.linkedInlineTable.filter(function (e) {
                                return e.inlineAreaId === it.get('id');
                            }).length <= 0) {

                                that.linkedInlineTable.push(
                                    {
                                        inlineAreaId: "#" + it.get('id'),
                                        parent: "#" + it.getAttribute('data-parentInlineId')
                                    }
                                );
                            }

                        }
                    }
                );

                that.addClickEventHandlers(pageData);


                //hide navigation for image picker areas
                if (pageData.hideNavigation) {
                    Y.one('#case-interview-form').one('#case-editor-continue-container').setStyle('display', 'none');
                    Y.one('#case-editor-main-header').setStyle('minHeight', '0px');
                    Y.one('#case-editor-section-title').setStyle('display', 'none');

                } else {
                    Y.one('#case-editor-main-header').setStyle('minHeight', '180px');
                    Y.one('#case-editor-section-title').setStyle('display', 'block');
                }

                that.fire('removeWaiting');
                that.fire('interview:viewRendered');
            },

            addClickEventHandlers : function(pageData) {
                const that = this;
                that.defaultNextLabel = Y.one('#case-editor-continue-container').one('.ui-case-editor-next').getContent();
                that.setCheckboxSelectionClasses();
                if(pageData.changeNextButtonLabelToNoneOfThem){
                    that.alterNextLabelDependingOnSelection();
                }

                that.get('handlers').push(Y.delegate('click', function(e) {
                    that.passClickEventToCheckbox(e);
                    that.setCheckboxSelectionClasses();
                    if(pageData.changeNextButtonLabelToNoneOfThem){
                        that.alterNextLabelDependingOnSelection();
                    }
                }, 'body', '.case-interview-field-checkbox', that));
            },

            alterNextLabelDependingOnSelection: function () {
                const nextButton = Y.one('#case-editor-continue-container .ui-case-editor-next');
                const isAnyFieldSelected = Y.some(Y.all('.checkbox-field'), function (f) {
                    return f.get('checked');
                }, this);
                const nextLabel = isAnyFieldSelected ? this.defaultNextLabel : 'Keine davon <i class="fa-solid fa-chevron-right"></i>';
                nextButton.setContent(nextLabel);
            },

            setCheckboxSelectionClasses: function () {
                Y.one('#case-interview-form').all('.case-interview-field-render-in-tiles .checkbox-field').each(
                    function (f) {
                        if (f.get('checked')) {
                            f.ancestor('.case-interview-field-render-in-tiles').addClass('case-interview-field-selected');
                        } else {
                            f.ancestor('.case-interview-field-render-in-tiles').removeClass('case-interview-field-selected');
                        }
                    }
                );
            },

            passClickEventToCheckbox: function (event) {
                if (event.target.test('input')) {
                    return;
                }
                const currentTarget = Y.one(event.currentTarget);
                if (currentTarget.hasClass('case-interview-field')) {
                    const checkBox = currentTarget.one('.checkbox-field');
                       if(checkBox){
                            checkBox.simulate('click');
                       }

                }
            },

            /**
             * Implementation of the required API. This will initialize this page.
             *
             * @param event The event containing the new content which has to be processed.
             *
             * @protected
             */
            _initPage: function (event) {
                var that = this,
                    container = that.get('container');

                Y.log("Initializing page...", "DEBUG", "CaseEditorAppInterviewPageForm");

                container.setHTML(event.newVal);
                that.addNavigationButtons(container);

                that.fire('interview:rendering', {state: this._pageState});

                // initialize the rest. - some of these methods need the content already in the DOM.
                that.setupAfterFormInit();
            },

            setupAfterFormInit: function () {

                if (!this.linkedInlineTable) {
                    this.linkedInlineTable = [];
                }

                Y.log("### interview form enhancement: " + this._pageState.multiAreaMeta.multiFieldAreaIndex);

                //making sure all fields are initialized
                Y.one("#case-interview-form").all(".native-field").each(function (field) {
                    if (!field.hasClass('half')) {
                        field.set('value', Y.smst.Utils.textAreaEscape(field.get('value')));

                        const f = Y.interview.FieldFactory.newField(field, this._pageState.formIndex, this);
                        this.fields[f.nodeId] = f;

                        //console.log("Setting up field "+f.nodeId);

                        f.render();
                    }
                }, this);

                //show the main area...
                this.fire("interview:showArea", {
                    area: 'inline_' + this._pageState.area,
                    isNew: false
                });

                this.__fieldRules.updateFieldRules(
                    this._pageState,
                    this.fields
                );

                this._setupLauncher();
                this._updateErrors();
                this._activateFieldHelp();
                this._setSmartCheckMode();
                this._mobilefielddecorator = new Y.MobileFieldDecorator();

                this._ready = true;

                Y.log("firing interview:rendered");

                this.fire('interview:rendered', {fields: this.fields, state: this._pageState});

                //sanity-check for inline-area-rendering
                if (VERBOSE) {
                    const rules = this._pageData.inlineAreaRules;

                    rules.forEach((rule) => {
                        if (Y.one('#inline_' + rule.id) == null) {
                            Y.error("Inline-Area not rendered: " + rule.id);
                        } else {
                            Y.log("Inline-Area present: " + rule.id);
                        }
                    });
                }
            },

            /**
             * Two things have to be done when an inline area appears:
             * 1. initialize all fields of this area
             * 2. touch each field once to trigger any visibility- or field-rule.
             *
             * @param event The event. This contains the now visible area and the inlineId of its dom-node.
             * @private
             */
            _afterShowArea: function (event) {
                const appearedFields = this._checkAppearedFields(event);
                this._touchFields(appearedFields); // this was part of content-interview.
            },

            /**
             * Wenn ein Inline-Area angezeigt wird, müssen die enthaltenen Felder überprüft werden.
             * @param event Das Event - mit dem area als property
             */
            _checkAppearedFields: function (event) {
                const appearedFields = [];

                Y.log("Check after area (" + event.area + ") appeared.");

                if (event.isNew) { // set in addMFA
                    this._pageState.areaIsProcessed = false;
                }

                Y.one("#case-interview-form").all(".native-field").each(function (fieldNode) {
                    const ancestor = fieldNode.ancestor('li');

                    //if top level, show all visible fields, otherwise only the ones using the inline id
                    if ((event.area === "inline_" + this._pageState.area && ancestor.getStyle('display') !== "none")
                        || ancestor.getAttribute('data-inlineid') === event.area) {
                        ancestor.setStyle('display', '');

                        const field = this.fields[fieldNode.get("id")];

                        // we have to collect all native-fields in this area.
                        if (field && (field.get('node').hasClass('native-field'))) {
                            field.show();

                            if (this._pageState.areaIsProcessed) {
                                field.check();
                            }

                            appearedFields.push(field);
                        }

                        if (event.value) {
                            Y.log("val for: " + field + " : " + event.value);
                            field && field.set('value', event.value, {src: 'tool'});
                        }
                    }
                }, this);

                return appearedFields;
            },

            _touchFields: function (fields) {
                Y.Array.each(fields, function (it) {
                    it.touch()
                });
            },

            /**
             * Wenn ein Inline-Area ausgeblendet wird müssen die enthaltenen Felder überprüft werden.
             * @param event Das Event - mit dem area als property
             */
            _checkDisappearedFields: function (event) {

                Y.log("Check after area (" + event.area + ") disappeared.");

                Y.one("#case-interview-form").all(".native-field").each(function (fieldNode) {
                    const ancestor = fieldNode.ancestor('li');

                    if (ancestor.getAttribute('data-inlineid') === event.area) {
                        //console.log("hide li "+ancestor.get('id'));

                        ancestor.setStyle('display', 'none');

                        const fId = fieldNode.get("id");
                        const field = this.fields[fId];

                        if (field) {
                            field.hide();
                        }
                    }
                }, this);
            },

            hasChanges: function () {
                let hasChanges = false;
                const that = this;

                if (this._smst_modified) {
                    return true;
                }

                Y.some(that.fields, function (f) {
                    if (f && f.isChanged()) {
                        hasChanges = true;
                        return true;
                    }
                    return false;
                }, that);
                return hasChanges;
            },

            /**
             * Before loading a new page this will check if the current page has any changes which would get lost in case
             * of a normal navigation. If changes are found this will prevent the next event.
             *
             * @param event the 'load' event which will get prevented if changes are found.
             * @private
             */
            _prepareUnload: function (event) {
                const that = this;
                if (!that._checkDisabled) {
                    Y.log("Checking for changes...", "DEBUG", "CaseEditorAppInterviewPageForm");
                    if (that.hasChanges()) {
                        event.preventDefault();
                    }
                }
            },

            /**
             * Like _prepareUnload but additionally checks if the current area is new or invalid.
             *
             * @param event The 'next' event which will get prevented if changes are found
             * @private
             */
            _prepareNext: function (event) {
                var that = this;
                Y.log("Checking area states...", "DEBUG", "CaseEditorAppInterviewPageForm");

                //ONSE-6335 never submit on *base
                if (that._pageState.isMultiFieldBase || that._pageState.isMultiFormBase) {

                    var cnt = Y.one('#ui-case-editor-interview-multif-add-multiple-count');
                    if (cnt !== null && cnt.getStyle('display') !== 'none' && cnt.get('value') !== '0') {
                        event.preventDefault();

                        this._createMultiEntries(cnt.get('value'));
                    }

                    return;
                }

                if (!that._pageState.areaIsValid || !that._pageState.areaIsProcessed) {
                    event.preventDefault();
                } else {
                    that._prepareUnload(event);
                }

            },

// --------------------------------------------------------------------------------------------------------------------
// ----- Save the current form ----------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------------------------------------

            /**
             * This is called when the user clicks on the next button and there are changes on the page or the page has
             * never been saved before.
             *
             * @param event The save event. When prevented this will pull up an overlay asking the user what to do.
             *          (save or abort)
             * @param saveQueue This method is part of a queue which is run during submit. If we prevent the event we also
             *                  need to stop the queue.
             * @private
             */
            _checkFields: function (event, saveQueue) {
                var that = this,
                    ok = true;

                Y.log("save:: check before submit...", 'DEBUG', "CaseEditorAppInterviewPageForm");
                // short cut
                if (event.force) {
                    return;
                }

                Y.each(that.fields, function (field) {

                    const visibleInliner = field.node.ancestor('li').getStyle('display') !== 'none';

                    Y.log(">>>>>>>> " + field.get('label') + ": " + visibleInliner);

                    if (visibleInliner) {

                        //Y.log(">>>>>>>> "+f.get('label')+": "+ f.readyForSubmit());

                        if (ok && !field.readyForSubmit()) {
                            Y.log("found error -> preventing default: " + field.nodeId + "/" + field.notVisible, 'INFO',
                                "CaseEditorAppInterviewPageForm");
                            ok = false;
                        } else if (!ok) {
                            // um alle Fehler auf einer Seite anzuzeigen testen wir nur noch schnell den Rest durch.
                            field.readyForSubmit();
                        }
                    }
                }, that);

                const messages = this.__fieldRules.checkRules(this._pageState);
                if (messages !== null) {
                    ok = false;
                }

                if (!ok) {
                    event.errorDescriptions && event.errorDescriptions.push(messages);
                    event.preventDefault();
                    // stop clears the queue
                    // run will fire its complete event
                    saveQueue.stop().run();
                }
            },

            _insertPlainValues: function () {
                Y.log("save:: inserting plain values before submit...", "DEBUG", "CaseEditorAppInterviewPageForm");
                Y.each(this.fields, function (f) {
                    if (f && f.plainValue) {
                        f.plainValue();
                    }
                }, this);
            },

            // FIXME: delete me
            _setProcessedInlineAreaIds: function () {
                const that = this,
                    pa = Y.one('#processedInlineAreas');

                that._pageData.inlineAreaRules.forEach(rule => {
                    try {
                        let shown = false;
                        const areaId = rule.id;
                        const id = `#inline_${areaId}`;
                        const area = Y.one(id);

                        if (!area) {
                            return;
                        }

                        if (VERBOSE) {
                            Y.log(`>>> ${areaId} / ${id}`);
                            Y.log(`inlineArea: ${area}:${area.get('id')}`);
                        }

                        if (area.getStyle('display') === 'none') {
                            if (VERBOSE) {
                                Y.log(`remove inlineArea: ${area}:${area.get('id')}`);
                            }
                            area.remove().destroy();
                        } else {
                            shown = true;
                        }

                        if (shown) {
                            pa.set('value', `${pa.get('value')},${areaId}`);
                        }
                    } catch (ex) {
                        //Y.app.alert(ex);
                        //Y.log(ex);
                    }
                });

                if (VERBOSE) {
                    Y.log("processedInlineAreas: " + pa.get('value'));
                }
            },

            _submit: function (event) {
                let that = this,
                    cfg,
                    navInfo = event.navInfo;

                Y.log("save:: submitting...", "DEBUG", "CaseEditorAppInterviewPageForm");

                cfg = {
                    on: {
                        success: function (id, response) {
                            Y.log('save:: successfully saved.', 'DEBUG', 'CaseEditorAppInterviewPageForm');
                            // before moving on we have to disable any further testing.
                            that._checkDisabled = true;
                            if (navInfo) {
                                Y.log('save:: found navinfo - redirecting...', 'DEBUG', 'CaseEditorAppInterviewPageForm');
                                that.fire('updateState', {raw: response.responseText, src: 'save', navInfo: navInfo});
                            } else {
                                Y.log('save:: updating state', 'DEBUG', 'CaseEditorAppInterviewPageForm');
                                that.fire('updateState', {
                                    raw: response.responseText,
                                    src: 'save',
                                    activateSmartCheck: event.activateSmartCheck
                                });
                            }
                        },
                        failure: function (id, response) {
                            Y.log('save:: failed to save changes: \n' + response.responseText, 'ERROR', 'CaseEditorAppInterviewPageForm');
                            that.fire('alert', {
                                msg: "Prüfe Deine Internetverbindung und versuche es erneut."
                            });
                        }
                    },
                    context: that,
                    method: 'POST',
                    timeout: 20000,
                    form: {
                        id: 'case-interview-form',
                        useDisabled: true
                    }
                };

                Y.io("api/state", cfg);
            },

            _save: function (event) {
                const that = this;
                const queue = new Y.AsyncQueue();

                if (!that._ready) {
                    Y.log('Not yet ready for submit.', 'DEBUG', 'CaseEditorAppInterviewPageForm');
                    return;
                }

                if (that.__saveQueueRunning) {
                    Y.log('Save queue still running.', 'DEBUG', 'CaseEditorAppInterviewPageForm');
                } else {
                    Y.log('Starting save queue...', 'INFO', 'CaseEditorAppInterviewPageForm');
                    that.__saveQueueRunning = true;

                    queue.onceAfter('complete', function () {
                        that.__saveQueueRunning = false;
                    });

                    // check for valid values
                    queue.add({
                        fn: that._checkFields,
                        args: [event, queue],
                        context: that,
                        timeout: -1 // this first call has to be synchronized. That way we are still able to prevent the
                                    // save event.
                    });

                    // Bring in the waiting mask, so the user sees that something is happening.
                    queue.add({
                        fn: function () {
                            this.fire('waiting', {msg: 'Dein Fall wird berechnet …'});
                        },
                        context: that
                    });

                    // replace the pretty printed values with their counterparts.
                    queue.add({
                        fn: that._insertPlainValues,
                        context: that
                    });

                    // replace the pretty printed values with their counterparts.
                    queue.add({
                        fn: that._setProcessedInlineAreaIds,
                        context: that
                    });

                    // do submit
                    queue.add({
                        fn: that._submit,
                        args: [event], // the event can have a navInfo inside which dictates the next page to show.
                        context: that
                    });

                    queue.run();
                }

            },

            _setSmartCheckMode: function () {
                if (Y.smartCheckMode) {
                    const node = Y.one('.ui-case-editor-next-and-smartcheck');

                    if (node) node.removeClass('ui-display-none');
                }
            },

            _showErrorMessage: function (msg) {
                const node = Y.one('#case-interview-form');
                if (node && msg) {
                    node.insert('<div class="ui-case-interview-inline-error app-alert app-alert-problem"><i class="fa-light fa-circle-exclamation"></i><div>' + msg + '</div></div>', 0);
                }
            },

            _updateErrors: function () {
                const that = this;

                if (!Y.globalErrors || !that._pageState || !Y.smartCheckMode) {
                    return;
                }

                Y.Array.each(Y.globalErrors, function (it) {

                    if (it.fields) {
                        Y.each(it.fields, function (it2) {
                            Y.some(this.fields, function (f) {
                                if (!f._hasError && f.node.getAttribute("name").indexOf(it2) >= 0) {
                                    if ((that._pageState.multiFieldIndex >= 0 ? it.index === that._pageState.multiFieldIndex : true) && it.formIndex === that._pageState.formIndex) {
                                        f.parentNode.addClass('smartcheck-field-attention-status');
                                    }
                                }
                            }, this);
                        }, this);
                    }
                }, that);

            },

            _activateFieldHelp: function () {
                let node = null;

                if (!this.fields || this.fields.length === 0 || this._pageData.checkboxArea) {
                    return;
                }

                Y.some(this.fields, function (field) {
                    if (field._hasError) {
                        if ('hidden' !== field.node.get('type')) {
                            const anc = field.node.ancestor('fieldset');

                            if (!anc || anc.getStyle('display') !== "none") {
                                node = field.node;
                                return true;
                            }
                        }
                    }
                }, this);

                if (!node) {
                    Y.some(Y.all('.onse-field'), function (f) {
                        if ('hidden' !== f.get('type')) {
                            const anc = f.ancestor('fieldset');

                            if (!anc || anc.getStyle('display') !== "none") {
                                node = f;
                                return true;
                            }
                        }
                    }, this);
                }

                if (node) {
                    const f = this.fields[node.get('id')]; //due to inline positioning this.fields might not be ordered. position using the first onse field

                    if (f && !Y.one('body').hasClass('ui-device-mobile')) {
                        // we disable the automatic focus for touch-devices, see NA-91
                        Y.later(100, this, function () {
                            f.node.focus();
                        });
                    }
                }
            },

            fixEncoding: function (str) {
                if (str == null) {
                    return str;
                }
                const ret = Y.smst.Utils.textAreaEscape(str);

                return Y.Escape.html(ret);
            },

            // -----------------------------------------------------------------------------------------------------------
            // --- inline areas ------------------------------------------------------------------------------------------
            // -----------------------------------------------------------------------------------------------------------

            linkedInlineTable: [],

            _processInlineAreaRulesAfterFieldChanged: function (e) {
                let fieldId = e.field.get('id');
                const rules = this._pageData.inlineAreaRules
                let ruleVisibility = null;
                let format;

                rules.forEach(rule => {
                    ruleVisibility = rule.visibility;
                    format = ruleVisibility.substring(ruleVisibility.indexOf('@') + 1);
                    format = format.substring(0, format.indexOf('/'));

                    if (VERBOSE) Y.log(">>> " + fieldId + ":" + ruleVisibility + ":" + ":" + format);

                    if (ruleVisibility.indexOf(fieldId) >= 0) {
                        //yui-element, expression parsen
                        const ruleStart = ruleVisibility.substring(0, ruleVisibility.indexOf(" "));
                        const ruleEnd = ruleVisibility.substring(ruleVisibility.indexOf(" ") + 1);
                        let value = null;
                        const yuiField = Y.one('#' + fieldId);
                        let useFallback = false;

                        if (VERBOSE) Y.log("EVAL_DYNAMIC:  MATCH: " + fieldId + ", format: " + format);

                        if (!yuiField) {
                            Y.error('Field is null: ' + fieldId);
                            return;
                        }

                        if (yuiField.hasClass('case-editor-interview-field-empty')) {
                            if (VERBOSE) Y.log("EVAL_DYNAMIC: EMPTY: " + fieldId);

                            useFallback = true;
                        } else {
                            //ausdruck anpassen und ggf. im eval den wert entsprechend des formates parsen lassen
                            if (format === 'X' || format === 'Y' || format === 'J') {
                                if (yuiField.hasClass('select')) {
                                    value = eval(ruleStart + '.get("value") !== ""');
                                } else {
                                    value = eval(ruleStart + '.get("checked") ');
                                }
                            } else {
                                value = eval('Y.UserCase.realValue(' + ruleStart + '.get("value"),"' + format + '") ');

                                if (Y.UserCase.isInvalidNumber(value, format)) {
                                    useFallback = true;
                                }
                            }
                        }

                        //defaultvalues
                        if (useFallback) {
                            const defaultValue = yuiField.getAttribute('data-defaultValue');
                            format = yuiField.getAttribute('data-format');

                            value = Y.UserCase.realValue(defaultValue, format);

                            if (VERBOSE) Y.log("EVAL_DYNAMIC: FALLBACK: " + fieldId + " -> " + value + "@" + defaultValue + ":" + format);
                        }

                        //finally construct and evaluate
                        ruleVisibility = value + ' ' + ruleEnd;

                        if (VERBOSE) Y.log("EVAL_DYNAMIC: " + rule + " >>> " + eval(ruleVisibility) + " , fallback: " + useFallback);

                        let ok = false;

                        try {
                            ok = eval(ruleVisibility);
                        } catch (ex) {
                            Y.log('EVAL_DYNAMIC: Exception: ' + rule);
                        }

                        if (ok) {
                            this._toggleInlineArea(rule.id, true, fieldId);
                        } else {
                            this._toggleInlineArea(rule.id, false);
                        }
                    }
                })
            },

            _toggleInlineArea: function (inlineAreaIdx, active, fieldId, preventCleanup) {
                let lastInline;
                const that = this;

                const modifiedInlineAreaId = '#inline_' + inlineAreaIdx,
                    inlineArea = Y.one(modifiedInlineAreaId);

                if (!inlineArea) {
                    Y.log("TOGGLE_INLINE -> AREA NOT FOUND: " + modifiedInlineAreaId + ":" + active + ":" + fieldId);
                    return;
                }

                if (VERBOSE) Y.log("TOGGLE_INLINE: " + modifiedInlineAreaId + ":" + active + ":" + fieldId + ":" + String(inlineArea));

                //ONSE-5913: store inline area dependencies so the can be safely unwound
                if (fieldId) {

                    //on page initialization values of the dirtycase are checked, so we need to make sure all inlines this one depends on are visible
                    lastInline = that._getDependencyInlinerParent(modifiedInlineAreaId);

                    while (lastInline !== modifiedInlineAreaId) {
                        if (VERBOSE) Y.log("checking visibility of inline dependency: " + lastInline);

                        if (!Y.one(lastInline)) {
                            if (VERBOSE) Y.log("skipping, main area reached");

                            break;
                        }

                        if (Y.one(lastInline).getStyle("display") === 'none') {
                            if (VERBOSE) Y.log("skipping this inliner due to invisible dependency");

                            //this is the important part: just return here if any parent inliner inliner is not visible!
                            return;
                        }

                        //fetch parent of this one
                        const nextLastInline = that._getDependencyInlinerParent(lastInline);
                        if (nextLastInline === lastInline) {
                            break;
                        } else {
                            lastInline = nextLastInline;
                        }
                    }
                } else if (!preventCleanup) {
                    lastInline = that._getLastDependencyInliner(modifiedInlineAreaId);

                    if (VERBOSE) Y.log("fetched last inline dependency: " + lastInline);

                    while (lastInline !== modifiedInlineAreaId) {
                        if (VERBOSE) Y.log("toggling off inline dependency: " + lastInline);

                        //toggle out, but prevent this loop from running again
                        that._toggleInlineArea(lastInline.substring(lastInline.lastIndexOf("_") + 1), false, null, true);

                        //fetch parent of this one
                        lastInline = that._getDependencyInlinerParent(lastInline);
                    }
                }
                //end ONSE-5913 / inline dependency management

                if (active) {
                    inlineArea.setStyle("display", "block");

                    if (VERBOSE) {
                        Y.log("TOGGLE_INLINE: STYLES_PARENT: " + stylesParent + ", prevLi: " + prevLi);
                    }

                    that.fire("interview:showArea", {area: inlineArea.get('id')});
                } else {
                    inlineArea.setStyle("display", "none");

                    that.fire("interview:hideInlineArea", {area: inlineArea.get('id')});
                }
            },

            /**
             * Returns the leaf node for the given id, so we can start there to unwind.
             *
             * @param id
             * @returns {*}
             * @private
             */
            _getLastDependencyInliner: function (id) {
                const that = this;

                that.linkedInlineTable.forEach(function (table) {
                    if (table.parent === id) {
                        return that._getLastDependencyInliner(table.inlineAreaId);
                    }
                })

                return id;
            },

            /**
             * Returns the parent id for a given inlineAreaId.
             *
             * @param id
             * @returns {*}
             * @private
             */
            _getDependencyInlinerParent: function (id) {
                this.linkedInlineTable.forEach(function (table) {
                    if (table.inlineAreaId === id) {
                        return table.parent;
                    }
                })
                return id;
            },

            _handleImageListClick: function (event) {
                event.halt();
                this._syncImageList(event);
                Y.later(300, this, function () {
                    this.fire('internalNext');
                });
            },

            _syncImageList: function (event) {
                const box = event.currentTarget.get('parentNode').get('parentNode');
                const select = box.one('.case-editor-interview-field-buttonpicker-select');

                select.all('option').each(function (opt) {
                    if (opt.get('value') === event.currentTarget.getAttribute('data-key')) {
                        opt.set('selected', 'selected');
                        this._smst_modified = true;
                    }
                }, this);
            },

            _setupLauncher: function () {
                Y.Array.each(this._pageState.launcher, function (it) {
                    if (it.link) {
                        for (var key in this.fields) {
                            const field = this.fields[key];
                            if (field.get('name') === it.field) {
                                field.node.removeClass('ui-display-none');
                                field._buttonContainer.one('.ui-launcher-delete').removeClass('ui-display-none');
                            }
                        }
                    }
                }, this);
            },

            addNavigationButtons: function (container) {
                const that = this;

                const node = container.one('#case-interview-form');
                Y.HandlebarsTemplates.append('case-editor-interview-navigation-form', node);

                //multifield+multiform: last area shows a small widget to jump to overview
                if (that._pageState && that._pageState.showAddLink) {
                    let nextLabel = "";

                    if (that._pageState.nextLabel) {
                        nextLabel = "<em>" + that._pageState.nextLabel.trim() + "</em>: ";
                    }

                    Y.one('#case-interview-form').append(
                        Y.HandlebarsTemplates.lookup('case-editor-interview-navigation-addMoreEntries')(
                            {
                                "nl": nextLabel,
                                "showAddLink": that._pageState.showAddLink
                            }
                        )
                    )
                }
            },


        }, {
            ATTRS: {
                ready: {
                    readOnly: true
                }
            }
        });


}, '1.0.0', {
    requires: [
        'app-overlays',
        'async-queue',
        'base-build',
        'case-editor-app-interview-page-view',
        // the chapters view is needed as long as multi-base-areas are handled by this class aswell.
        'case-editor-app-interview-page-chapters',
        'case-editor-app-interview-page-multioverview',
        'case-editor-app-interview-locked-area',
        'case-editor-app-interview-field-help',
        'escape',
        'event',
        'mobilefield',
        'node-event-simulate',
        'interview-field-factory',
        'interview-fieldrules',
        'smst-lang',
        "smst-utils",
        'view'
    ]
});