




























































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import DashboardServiceTile from '@/components/case-list/DashboardServiceTile.vue'
import Alert from '@/components/ui/Alert.vue'
import Button from '@/components/ui/Button.vue'
import Dialog from '@/components/ui/Dialog.vue'

@Component({ components: { Button, Alert, DashboardServiceTile, Dialog } })
export default class SidebarComponent extends Vue {
  @Prop() vastUrl!: string
  @Prop() beleguanUrl!: string

  dialogOpen = false
  private dialog = false

  showDialog(show: boolean) {
    this.dialog = show
    this.dialogOpen = show
  }

  updated() {
    this.dialog = this.dialogOpen
  }
}
