/**
 *  Plugin, dass ein Widget versteckt sobald der X-Knopf im Kopf gedrückt wird.
 *
 * Config:
 *  template: Das HTML für den Knopf
 *  append: true -> wird an den Inhalt des Headers angehängt. false -> wird dem Inhalt vorangestellt.
 */
YUI.add('gorilla-widget-x-button-plugin', function(Y) {

    var XButtonPlugin,
        OVERLAY = 'overlay',
        XBUTTON = 'xbutton',
        DEFAULT_TEMPLATE = '<i class="fa-light fa-times"></i>',
        CLASSES = {
            cancel: Y.ClassNameManager.getClassName( OVERLAY, XBUTTON)
        };

    XButtonPlugin = function(config) {
        if( !config.template ) {
            config.template = DEFAULT_TEMPLATE;
        }
        config.section = Y.WidgetStdMod.HEADER;
        config.classes = CLASSES;
        XButtonPlugin.superclass.constructor.apply(this, arguments);
    };

    Y.mix(XButtonPlugin, {
        NAME: 'XButton', // component name
        NS: 'xbtn' // plugin namespace
    });

    Y.extend( XButtonPlugin, Y.gorilla.widget.CancelButtonPlugin );    


    // add this to our namespace
    Y.namespace("gorilla.widget").XButtonPlugin = XButtonPlugin;

}, '1.0' ,{requires:['gorilla-widget-cancel-button-plugin']});