SMST_config = {
    fetchCSS: false,
    lang: "de-DE",
    comboBase: "/js/combo?",
    maxURLLength: 1024,
    root: "/yui/",
    base: "/js/yui/",
    gallery: "gallery-2011.03.11-23-49",
    combine: true,
    debug: false,
    throwFail: false,
    insertBefore: "favicon",
    groups: {
        "/gorilla/alert/": {
            combine: true,
            base: "/js/gorilla/alert/",
            root: "/gorilla/alert/",
            modules: {
                "gorilla-alert": {
                    path: "alert.js",
                    requires: [
                        "overlay",
                        "async-queue",
                        "substitute",
                        "gorilla-widget-x-button-plugin",
                        "gorilla-overlay-anim-plugin",
                        "gallery-overlay-extras"
                    ]
                }
            }
        },
        "/gorilla/messageListBox-collapse-plugin/": {
            combine: true,
            base: "/js/gorilla/messageListBox-collapse-plugin/",
            root: "/gorilla/messageListBox-collapse-plugin/",
            modules: {
                "gorilla-messageListBox-collapse-plugin": {
                    path: "messageListBox-collapse-plugin.js",
                    requires: [
                        "plugin",
                        "gorilla-messageListBox"
                    ]
                }
            }
        },
        "/gorilla/messageListBox-hide-plugin/": {
            combine: true,
            base: "/js/gorilla/messageListBox-hide-plugin/",
            root: "/gorilla/messageListBox-hide-plugin/",
            modules: {
                "gorilla-messageListBox-hide-plugin": {
                    path: "messageListBox-hide-plugin.js",
                    requires: [
                        "plugin",
                        "gorilla-messageListBox"
                    ]
                }
            }
        },
        "/gorilla/messageListBox/": {
            combine: true,
            base: "/js/gorilla/messageListBox/",
            root: "/gorilla/messageListBox/",
            modules: {
                "gorilla-messageListBox": {
                    path: "messageListBox.js",
                    requires: [
                        "widget",
                        "substitute",
                        "dump",
                        "array-extras"
                    ]
                }
            }
        },
        "/gorilla/overlay-anim-plugin/": {
            combine: true,
            base: "/js/gorilla/overlay-anim-plugin/",
            root: "/gorilla/overlay-anim-plugin/",
            modules: {
                "gorilla-overlay-anim-plugin": {
                    path: "overlay-anim-plugin.js",
                    requires: [
                        "overlay",
                        "plugin",
                        "anim"
                    ]
                }
            }
        },
        "/gorilla/overlay-io-plugin/": {
            combine: true,
            base: "/js/gorilla/overlay-io-plugin/",
            root: "/gorilla/overlay-io-plugin/",
            modules: {
                "gorilla-overlay-io-plugin": {
                    path: "overlay-io-plugin.js",
                    requires: [
                        "overlay",
                        "gorilla-widget-io"
                    ]
                }
            }
        },
        "/gorilla/tab-io-plugin/": {
            combine: true,
            base: "/js/gorilla/tab-io-plugin/",
            root: "/gorilla/tab-io-plugin/",
            modules: {
                "gorilla-tab-io-plugin": {
                    path: "tab-io-plugin.js",
                    requires: [
                        "tabview",
                        "gorilla-widget-io"
                    ]
                }
            }
        },
        "/gorilla/widget-cancel-button-plugin/": {
            combine: true,
            base: "/js/gorilla/widget-cancel-button-plugin/",
            root: "/gorilla/widget-cancel-button-plugin/",
            modules: {
                "gorilla-widget-cancel-button-plugin": {
                    path: "widget-cancel-button-plugin.js",
                    requires: [
                        "plugin",
                        "widget",
                        "widget-stdmod"
                    ]
                }
            }
        },
        "/gorilla/widget-escape-hide-plugin/": {
            combine: true,
            base: "/js/gorilla/widget-escape-hide-plugin/",
            root: "/gorilla/widget-escape-hide-plugin/",
            modules: {
                "gorilla-widget-escape-hide-plugin": {
                    path: "widget-escape-hide-plugin.js",
                    requires: [
                        "plugin",
                        "node"
                    ]
                }
            }
        },
        "/gorilla/widget-io-cache-plugin/": {
            combine: true,
            base: "/js/gorilla/widget-io-cache-plugin/",
            root: "/gorilla/widget-io-cache-plugin/",
            modules: {
                "gorilla-widget-io-cache-plugin": {
                    path: "widget-io-cache-plugin.js",
                    requires: [
                        "cache",
                        "gorilla-widget-io"
                    ]
                }
            }
        },
        "/gorilla/widget-io/": {
            combine: true,
            base: "/js/gorilla/widget-io/",
            root: "/gorilla/widget-io/",
            modules: {
                "gorilla-widget-io": {
                    path: "widget-io.js",
                    requires: [
                        "plugin",
                        "widget",
                        "io-base"
                    ],
                    supersedes: ["gallery-widget-io"]
                }
            }
        },
        "/gorilla/widget-x-button-plugin/": {
            combine: true,
            base: "/js/gorilla/widget-x-button-plugin/",
            root: "/gorilla/widget-x-button-plugin/",
            modules: {
                "gorilla-widget-x-button-plugin": {
                    path: "widget-x-button-plugin.js",
                    requires: ["gorilla-widget-cancel-button-plugin"]
                }
            }
        },
        "/smst/_advanced-help-repository/": {
            combine: true,
            base: "/js/smst/_advanced-help-repository/",
            root: "/smst/_advanced-help-repository/",
            modules: {
                "_advanced-help-repository": {
                    path: "_advanced-help-repository.js",
                    requires: ["io"]
                }
            }
        },
        "/smst/_google-analytics-repository/": {
            combine: true,
            base: "/js/smst/_google-analytics-repository/",
            root: "/smst/_google-analytics-repository/",
            modules: {
                "_google-analytics-repository": {
                    path: "_google-analytics-repository.js",
                    requires: []
                }
            }
        },
        "/smst/_interview-field-repository/": {
            combine: true,
            base: "/js/smst/_interview-field-repository/",
            root: "/smst/_interview-field-repository/",
            modules: {
                "_interview-field-repository": {
                    path: "_interview-field-repository.js",
                    requires: []
                }
            }
        },
        "/smst/analysis-preview/": {
            combine: true,
            base: "/js/smst/analysis-preview/",
            root: "/smst/analysis-preview/",
            modules: {
                "analysis-preview": {
                    path: "analysis-preview.js",
                    requires: ["widget"]
                }
            }
        },
        "/smst/app-header/": {
            combine: true,
            base: "/js/smst/app-header/",
            root: "/smst/app-header/",
            modules: {
                "app-header": {
                    path: "app-header.js",
                    requires: ["event-custom"]
                }
            }
        },
        "/smst/app-help/": {
            combine: true,
            base: "/js/smst/app-help/",
            root: "/smst/app-help/",
            modules: {
                "app-help": {
                    path: "app-help.js",
                    requires: [
                        "_advanced-help-repository",
                        "_interview-field-repository",
                        "app-overlays",
                        "base-build",
                        "event-base",
                        "event-custom",
                        "handlebars-view",
                        "frame",
                        "smart-handlebars-templates"
                    ]
                }
            }
        },
        "/smst/app-overlays/": {
            combine: true,
            base: "/js/smst/app-overlays/",
            root: "/smst/app-overlays/",
            modules: {
                "app-overlays": {
                    path: "app-overlays.js",
                    requires: [
                        "event-custom",
                        "smst-lang",
                        "smst-overlays"
                    ]
                }
            }
        },
        "/smst/case-editor-app-analysis/": {
            combine: true,
            base: "/js/smst/case-editor-app-analysis/",
            root: "/smst/case-editor-app-analysis/",
            modules: {
                "case-editor-app-analysis": {
                    path: "case-editor-app-analysis.js",
                    requires: [
                        "app",
                        "base-build",
                        "case-editor-app-base",
                        "handlebars-view",
                        "io-base",
                        "node",
                        "smst-lang",
                        "analysis-preview",
                        "substitute",
                        "node-event-simulate",
                        "print-preview",
                        "spreadsheets",
                        "spreadsheet"
                    ]
                }
            }
        },
        "/smst/case-editor-app-assessment/": {
            combine: true,
            base: "/js/smst/case-editor-app-assessment/",
            root: "/smst/case-editor-app-assessment/",
            modules: {
                "case-editor-app-assessment": {
                    path: "case-editor-app-assessment.js",
                    requires: [
                        "app",
                        "base-build",
                        "case-editor-app-base",
                        "handlebars-view",
                        "io-base",
                        "node",
                        "smst-lang",
                        "oop",
                        "slider",
                        "node-load",
                        "datatype-number"
                    ]
                }
            }
        },
        "/smst/case-editor-app-back-to-est/": {
            combine: true,
            base: "/js/smst/case-editor-app-back-to-est/",
            root: "/smst/case-editor-app-back-to-est/",
            modules: {
                "case-editor-app-back-to-est": {
                    path: "case-editor-app-back-to-est.js",
                    requires: [
                        "app",
                        "base-build",
                        "case-editor-app-base",
                        "io-base",
                        "node",
                        "smst-lang"
                    ]
                }
            }
        },
        "/smst/case-editor-app-base/": {
            combine: true,
            base: "/js/smst/case-editor-app-base/",
            root: "/smst/case-editor-app-base/",
            modules: {
                "case-editor-app-base": {
                    path: "case-editor-app-base.js",
                    requires: [
                        "app",
                        "base-build",
                        "smst-lang"
                    ]
                }
            }
        },
        "/smst/case-editor-app-decree/": {
            combine: true,
            base: "/js/smst/case-editor-app-decree/",
            root: "/smst/case-editor-app-decree/",
            modules: {
                "case-editor-app-decree": {
                    path: "case-editor-app-decree.js",
                    requires: [
                        "app",
                        "base-build",
                        "smart-handlebars-templates",
                        "case-editor-app-base",
                        "io-base",
                        "json-parse",
                        "node",
                        "smst-utils"
                    ]
                }
            }
        },
        "/smst/case-editor-app-filing2-documents/": {
            combine: true,
            base: "/js/smst/case-editor-app-filing2-documents/",
            root: "/smst/case-editor-app-filing2-documents/",
            modules: {
                "case-editor-app-filing2-documents": {
                    path: "case-editor-app-filing2-documents.js",
                    requires: [
                        "app",
                        "base-build",
                        "smart-handlebars-templates",
                        "case-editor-app-base",
                        "io-base",
                        "json-parse",
                        "node",
                        "smst-lang"
                    ]
                }
            }
        },
        "/smst/case-editor-app-filing2/": {
            combine: true,
            base: "/js/smst/case-editor-app-filing2/",
            root: "/smst/case-editor-app-filing2/",
            modules: {
                "case-editor-app-filing2": {
                    path: "case-editor-app-filing2.js",
                    requires: [
                        "app",
                        "base-build",
                        "smart-handlebars-templates",
                        "case-editor-app-base",
                        "case-editor-app-filing2-documents",
                        "io-base",
                        "json-parse",
                        "node",
                        "smst-lang"
                    ]
                }
            }
        },
        "/smst/case-editor-app-interview-field-help/": {
            combine: true,
            base: "/js/smst/case-editor-app-interview-field-help/",
            root: "/smst/case-editor-app-interview-field-help/",
            modules: {
                "case-editor-app-interview-field-help": {
                    path: "case-editor-app-interview-field-help.js",
                    requires: [
                        "base-build",
                        "event",
                        "smart-handlebars-templates",
                        "smst-lang",
                        "view"
                    ]
                }
            }
        },
        "/smst/case-editor-app-interview-link-viewer/": {
            combine: true,
            base: "/js/smst/case-editor-app-interview-link-viewer/",
            root: "/smst/case-editor-app-interview-link-viewer/",
            modules: {
                "case-editor-app-interview-link-viewer": {
                    path: "case-editor-app-interview-link-viewer.js",
                    requires: [
                        "app-overlays",
                        "base-build",
                        "event",
                        "smart-handlebars-templates",
                        "smst-lang"
                    ]
                }
            }
        },
        "/smst/case-editor-app-interview-locked-area/": {
            combine: true,
            base: "/js/smst/case-editor-app-interview-locked-area/",
            root: "/smst/case-editor-app-interview-locked-area/",
            modules: {
                "case-editor-app-interview-locked-area": {
                    path: "case-editor-app-interview-locked-area.js",
                    requires: [
                        "base-build",
                        "event",
                        "smart-handlebars-templates",
                        "smst-lang",
                        "view"
                    ]
                }
            }
        },
        "/smst/case-editor-app-interview-page-chapters/": {
            combine: true,
            base: "/js/smst/case-editor-app-interview-page-chapters/",
            root: "/smst/case-editor-app-interview-page-chapters/",
            modules: {
                "case-editor-app-interview-page-chapters": {
                    path: "case-editor-app-interview-page-chapters.js",
                    requires: [
                        "base-build",
                        "case-editor-app-interview-page-view",
                        "event",
                        "smart-handlebars-templates",
                        "smst-lang",
                        "view"
                    ]
                }
            }
        },
        "/smst/case-editor-app-interview-page-form/": {
            combine: true,
            base: "/js/smst/case-editor-app-interview-page-form/",
            root: "/smst/case-editor-app-interview-page-form/",
            modules: {
                "case-editor-app-interview-page-form": {
                    path: "case-editor-app-interview-page-form.js",
                    requires: [
                        "app-overlays",
                        "async-queue",
                        "base-build",
                        "case-editor-app-interview-page-view",
                        "case-editor-app-interview-page-chapters",
                        "case-editor-app-interview-page-multioverview",
                        "case-editor-app-interview-locked-area",
                        "case-editor-app-interview-field-help",
                        "escape",
                        "event",
                        "mobilefield",
                        "node-event-simulate",
                        "interview-field-factory",
                        "interview-fieldrules",
                        "smst-lang",
                        "smst-utils",
                        "view"
                    ]
                }
            }
        },
        "/smst/case-editor-app-interview-page-multioverview/": {
            combine: true,
            base: "/js/smst/case-editor-app-interview-page-multioverview/",
            root: "/smst/case-editor-app-interview-page-multioverview/",
            modules: {
                "case-editor-app-interview-page-multioverview": {
                    path: "case-editor-app-interview-page-multioverview.js",
                    requires: [
                        "app-overlays",
                        "base-build",
                        "case-editor-app-interview-page-view",
                        "escape",
                        "event",
                        "smart-handlebars-templates",
                        "smst-lang",
                        "user-case",
                        "view"
                    ]
                }
            }
        },
        "/smst/case-editor-app-interview-page-summary/": {
            combine: true,
            base: "/js/smst/case-editor-app-interview-page-summary/",
            root: "/smst/case-editor-app-interview-page-summary/",
            modules: {
                "case-editor-app-interview-page-summary": {
                    path: "case-editor-app-interview-page-summary.js",
                    requires: [
                        "base-build",
                        "case-editor-app-interview-page-view",
                        "event",
                        "smst-lang",
                        "view"
                    ]
                }
            }
        },
        "/smst/case-editor-app-interview-page-view/": {
            combine: true,
            base: "/js/smst/case-editor-app-interview-page-view/",
            root: "/smst/case-editor-app-interview-page-view/",
            modules: {
                "case-editor-app-interview-page-view": {
                    path: "case-editor-app-interview-page-view.js",
                    requires: [
                        "view",
                        "base-build",
                        "io-base",
                        "json-parse",
                        "node",
                        "smst-lang"
                    ]
                }
            }
        },
        "/smst/case-editor-app-interview-registerCTA/": {
            combine: true,
            base: "/js/smst/case-editor-app-interview-registerCTA/",
            root: "/smst/case-editor-app-interview-registerCTA/",
            modules: {
                "case-editor-app-interview-registerCTA": {
                    path: "case-editor-app-interview-registerCTA.js",
                    requires: [
                        "base-build",
                        "node",
                        "smart-handlebars-templates"
                    ]
                }
            }
        },
        "/smst/case-editor-app-interview-reports/": {
            combine: true,
            base: "/js/smst/case-editor-app-interview-reports/",
            root: "/smst/case-editor-app-interview-reports/",
            modules: {
                "case-editor-app-interview-reports": {
                    path: "case-editor-app-interview-reports.js",
                    requires: [
                        "base-build",
                        "node",
                        "smart-handlebars-templates"
                    ]
                }
            }
        },
        "/smst/case-editor-app-interview-taxnumberdecorator/": {
            combine: true,
            base: "/js/smst/case-editor-app-interview-taxnumberdecorator/",
            root: "/smst/case-editor-app-interview-taxnumberdecorator/",
            modules: {
                "taxnumberdecorator": {
                    path: "case-editor-app-interview-taxnumberdecorator.js",
                    requires: [
                        "base-build",
                        "event",
                        "interview-fields",
                        "interview-field-factory",
                        "smst-lang"
                    ]
                }
            }
        },
        "/smst/case-editor-app-interview/": {
            combine: true,
            base: "/js/smst/case-editor-app-interview/",
            root: "/smst/case-editor-app-interview/",
            modules: {
                "case-editor-app-interview": {
                    path: "case-editor-app-interview.js",
                    requires: [
                        "app",
                        "app-overlays",
                        "base-build",
                        "case-editor-app-base",
                        "case-editor-app-interview-page-chapters",
                        "case-editor-app-interview-page-form",
                        "case-editor-app-interview-page-multioverview",
                        "case-editor-app-interview-page-summary",
                        "case-editor-app-interview-registerCTA",
                        "case-editor-app-interview-reports",
                        "io-base",
                        "json-parse",
                        "node",
                        "querystring-stringify-simple",
                        "smst-lang",
                        "user-case"
                    ]
                }
            }
        },
        "/smst/case-editor-app-search/": {
            combine: true,
            base: "/js/smst/case-editor-app-search/",
            root: "/smst/case-editor-app-search/",
            modules: {
                "case-editor-app-search": {
                    path: "case-editor-app-search.js",
                    requires: [
                        "app",
                        "base-build",
                        "case-editor-app-base",
                        "io-base",
                        "node",
                        "smst-lang"
                    ]
                }
            }
        },
        "/smst/case-editor-app-summary/": {
            combine: true,
            base: "/js/smst/case-editor-app-summary/",
            root: "/smst/case-editor-app-summary/",
            modules: {
                "case-editor-app-summary": {
                    path: "case-editor-app-summary.js",
                    requires: [
                        "app",
                        "base-build",
                        "case-editor-app-base",
                        "case-editor-app-interview-page-summary",
                        "io-base",
                        "node",
                        "smst-lang",
                        "smst-utils",
                        "oop",
                        "datatype-number"
                    ]
                }
            }
        },
        "/smst/case-editor-app-taxmeter/": {
            combine: true,
            base: "/js/smst/case-editor-app-taxmeter/",
            root: "/smst/case-editor-app-taxmeter/",
            modules: {
                "case-editor-app-taxmeter": {
                    path: "case-editor-app-taxmeter.js",
                    requires: [
                        "app",
                        "base-build",
                        "case-editor-app-base",
                        "io-base",
                        "node",
                        "smst-lang"
                    ]
                }
            }
        },
        "/smst/case-editor-header-search-view/": {
            combine: true,
            base: "/js/smst/case-editor-header-search-view/",
            root: "/smst/case-editor-header-search-view/",
            modules: {
                "case-editor-header-search-view": {
                    path: "case-editor-header-search-view.js",
                    requires: [
                        "base-build",
                        "event",
                        "handlebars-view",
                        "node"
                    ]
                }
            }
        },
        "/smst/case-editor-sidebar-navigator/": {
            combine: true,
            base: "/js/smst/case-editor-sidebar-navigator/",
            root: "/smst/case-editor-sidebar-navigator/",
            modules: {
                "case-editor-sidebar-navigator": {
                    path: "case-editor-sidebar-navigator.js",
                    requires: [
                        "base-build",
                        "gallery-port",
                        "gallery-treeview",
                        "json",
                        "model",
                        "node",
                        "smst-lang",
                        "smst-utils",
                        "transition",
                        "view"
                    ]
                }
            }
        },
        "/smst/case-editor-sidebar/": {
            combine: true,
            base: "/js/smst/case-editor-sidebar/",
            root: "/smst/case-editor-sidebar/",
            modules: {
                "case-editor-sidebar": {
                    path: "case-editor-sidebar.js",
                    requires: [
                        "base-build",
                        "case-editor-sidebar-navigator",
                        "case-editor-app-assessment",
                        "event",
                        "json-parse",
                        "node",
                        "view",
                        "transition"
                    ]
                }
            }
        },
        "/smst/case-editor-views/": {
            combine: true,
            base: "/js/smst/case-editor-views/",
            root: "/smst/case-editor-views/",
            modules: {
                "case-editor-views": {
                    path: "case-editor-views.js",
                    requires: [
                        "base-build",
                        "case-editor-header-search-view",
                        "event",
                        "handlebars-view",
                        "node",
                        "smst-lang",
                        "view",
                        "anim"
                    ]
                }
            }
        },
        "/smst/case-editor/": {
            combine: true,
            base: "/js/smst/case-editor/",
            root: "/smst/case-editor/",
            modules: {
                "case-editor": {
                    path: "case-editor.js",
                    requires: [
                        "app",
                        "app-header",
                        "app-overlays",
                        "base-build",
                        "case-editor-app-analysis",
                        "case-editor-app-taxmeter",
                        "case-editor-app-assessment",
                        "case-editor-app-back-to-est",
                        "case-editor-app-decree",
                        "case-editor-app-filing2",
                        "case-editor-app-interview",
                        "case-editor-app-search",
                        "case-editor-app-summary",
                        "case-editor-sidebar",
                        "case-editor-views",
                        "case-editor-app-interview-link-viewer",
                        "dev-reports",
                        "interview-errors",
                        "interview-focus",
                        "interview-help",
                        "login",
                        "print-preview",
                        "registration",
                        "smst-config",
                        "smst-overlays",
                        "spreadsheets",
                        "taxnumberdecorator"
                    ]
                }
            }
        },
        "/smst/case-manager-views/": {
            combine: true,
            base: "/js/smst/case-manager-views/",
            root: "/smst/case-manager-views/",
            modules: {
                "case-manager-views": {
                    path: "case-manager-views.js",
                    requires: [
                        "base-build",
                        "handlebars-view",
                        "case-manager.js"
                    ]
                }
            }
        },
        "/smst/case-manager/": {
            combine: true,
            base: "/js/smst/case-manager/",
            root: "/smst/case-manager/",
            modules: {
                "case-manager": {
                    path: "case-manager.js",
                    requires: [
                        "app",
                        "app-header",
                        "app-overlays",
                        "base-build",
                        "case-manager-views",
                        "case-models",
                        "event",
                        "smart-handlebars-templates",
                        "handlebars-view",
                        "node-event-simulate",
                        "model-list",
                        "smst-lang",
                        "smst-overlays"
                    ]
                }
            }
        },
        "/smst/case-models/": {
            combine: true,
            base: "/js/smst/case-models/",
            root: "/smst/case-models/",
            modules: {
                "case-models": {
                    path: "case-models.js",
                    requires: [
                        "base-build",
                        "datatype-number",
                        "io-base",
                        "json-parse",
                        "model",
                        "model-sync-rest",
                        "smst-lang",
                        "user-case"
                    ]
                }
            }
        },
        "/smst/console-fix/": {
            combine: true,
            base: "/js/smst/console-fix/",
            root: "/smst/console-fix/",
            modules: {
                "console-fix": {
                    path: "console-fix.js",
                    requires: []
                }
            }
        },
        "/smst/dev-reports/": {
            combine: true,
            base: "/js/smst/dev-reports/",
            root: "/smst/dev-reports/",
            modules: {
                "dev-reports": {
                    path: "dev-reports.js",
                    requires: [
                        "base-build",
                        "node",
                        "json",
                        "node-event-simulate"
                    ]
                }
            }
        },
        "/smst/groovy-log/": {
            combine: true,
            base: "/js/smst/groovy-log/",
            root: "/smst/groovy-log/",
            modules: {
                "groovy-log": {
                    path: "groovy-log.js",
                    requires: [
                        "node",
                        "node-load",
                        "event",
                        "io",
                        "smst-config",
                        "escape"
                    ]
                }
            }
        },
        "/smst/handlebars-view/": {
            combine: true,
            base: "/js/smst/handlebars-view/",
            root: "/smst/handlebars-view/",
            modules: {
                "handlebars-view": {
                    path: "handlebars-view.js",
                    requires: [
                        "base-build",
                        "smart-handlebars-templates",
                        "view"
                    ]
                }
            }
        },
        "/smst/header-views/": {
            combine: true,
            base: "/js/smst/header-views/",
            root: "/smst/header-views/",
            modules: {
                "header-views": {
                    path: "header-views.js",
                    requires: [
                        "anim",
                        "app-overlays",
                        "base-build",
                        "handlebars-view",
                        "transition",
                        "smart-handlebars-templates"
                    ]
                }
            }
        },
        "/smst/interview-autosuggest-overwrite-plugin/": {
            combine: true,
            base: "/js/smst/interview-autosuggest-overwrite-plugin/",
            root: "/smst/interview-autosuggest-overwrite-plugin/",
            modules: {
                "interview-autosuggest-overwrite-plugin": {
                    path: "interview-autosuggest-overwrite-plugin.js",
                    requires: [
                        "base-build",
                        "plugin",
                        "node"
                    ]
                }
            }
        },
        "/smst/interview-errors/": {
            combine: true,
            base: "/js/smst/interview-errors/",
            root: "/smst/interview-errors/",
            modules: {
                "interview-errors": {
                    path: "interview-errors.js",
                    requires: ["base-build"]
                }
            }
        },
        "/smst/interview-field-factory/": {
            combine: true,
            base: "/js/smst/interview-field-factory/",
            root: "/smst/interview-field-factory/",
            modules: {
                "interview-field-factory": {
                    path: "interview-field-factory.js",
                    requires: [
                        "base-build",
                        "interview-fields",
                        "interview-range-field",
                        "interview-tools-plugin",
                        "interview-full-year-plugin",
                        "interview-autosuggest-overwrite-plugin",
                        "json-parse"
                    ]
                }
            }
        },
        "/smst/interview-field-mobile/": {
            combine: true,
            base: "/js/smst/interview-field-mobile/",
            root: "/smst/interview-field-mobile/",
            modules: {
                "mobilefield": {
                    path: "interview-field-mobile.js",
                    requires: [
                        "base-build",
                        "event",
                        "interview-fields",
                        "interview-field-factory",
                        "smst-lang",
                        "smart-form"
                    ]
                }
            }
        },
        "/smst/interview-fieldrules/": {
            combine: true,
            base: "/js/smst/interview-fieldrules/",
            root: "/smst/interview-fieldrules/",
            modules: {
                "interview-fieldrules": {
                    path: "interview-fieldrules.js",
                    requires: [
                        "base-build",
                        "event"
                    ]
                }
            }
        },
        "/smst/interview-fields/": {
            combine: true,
            base: "/js/smst/interview-fields/",
            root: "/smst/interview-fields/",
            modules: {
                "interview-fields": {
                    path: "interview-fields.js",
                    requires: [
                        "autocomplete",
                        "autocomplete-filters",
                        "autocomplete-highlighters",
                        "datasource-jsonschema",
                        "datasource-local",
                        "event",
                        "event-custom",
                        "event-focus",
                        "event-valuechange",
                        "interview-format-factory",
                        "interview-ref-field-plugin",
                        "interview-autosuggest-overwrite-plugin",
                        "json",
                        "smart-formats",
                        "user-case"
                    ]
                }
            }
        },
        "/smst/interview-focus/": {
            combine: true,
            base: "/js/smst/interview-focus/",
            root: "/smst/interview-focus/",
            modules: {
                "interview-focus": {
                    path: "interview-focus.js",
                    requires: [
                        "base-build",
                        "event-delegate",
                        "node-event-delegate"
                    ]
                }
            }
        },
        "/smst/interview-format-factory/": {
            combine: true,
            base: "/js/smst/interview-format-factory/",
            root: "/smst/interview-format-factory/",
            modules: {
                "interview-format-factory": {
                    path: "interview-format-factory.js",
                    requires: ["smart-formats"]
                }
            }
        },
        "/smst/interview-full-year-plugin/": {
            combine: true,
            base: "/js/smst/interview-full-year-plugin/",
            root: "/smst/interview-full-year-plugin/",
            modules: {
                "interview-full-year-plugin": {
                    path: "interview-full-year-plugin.js",
                    requires: [
                        "base-build",
                        "plugin",
                        "node"
                    ]
                }
            }
        },
        "/smst/interview-help/": {
            combine: true,
            base: "/js/smst/interview-help/",
            root: "/smst/interview-help/",
            modules: {
                "interview-help": {
                    path: "interview-help.js",
                    requires: ["oop"]
                }
            }
        },
        "/smst/interview-range-field/": {
            combine: true,
            base: "/js/smst/interview-range-field/",
            root: "/smst/interview-range-field/",
            modules: {
                "interview-range-field": {
                    path: "interview-range-field.js",
                    requires: [
                        "oop",
                        "interview-fields"
                    ]
                }
            }
        },
        "/smst/interview-ref-field-plugin/": {
            combine: true,
            base: "/js/smst/interview-ref-field-plugin/",
            root: "/smst/interview-ref-field-plugin/",
            modules: {
                "interview-ref-field-plugin": {
                    path: "interview-ref-field-plugin.js",
                    requires: [
                        "base",
                        "plugin",
                        "node"
                    ]
                }
            }
        },
        "/smst/interview-tools-plugin/": {
            combine: true,
            base: "/js/smst/interview-tools-plugin/",
            root: "/smst/interview-tools-plugin/",
            modules: {
                "interview-tools-plugin": {
                    path: "interview-tools-plugin.js",
                    requires: [
                        "plugin",
                        "io-base",
                        "json-parse",
                        "tool-routes-panel",
                        "datatype-number-format"
                    ]
                }
            }
        },
        "/smst/login/": {
            combine: true,
            base: "/js/smst/login/",
            root: "/smst/login/",
            modules: {
                "login": {
                    path: "login.js",
                    requires: [
                        "base-build",
                        "node"
                    ]
                }
            }
        },
        "/smst/mobile-transformer/": {
            combine: true,
            base: "/js/smst/mobile-transformer/",
            root: "/smst/mobile-transformer/",
            modules: {
                "mobile-transformer": {
                    path: "mobile-transformer.js",
                    requires: [
                        "base-build",
                        "event",
                        "node",
                        "view"
                    ]
                }
            }
        },
        "/smst/print-preview/": {
            combine: true,
            base: "/js/smst/print-preview/",
            root: "/smst/print-preview/",
            modules: {
                "print-preview": {
                    path: "print-preview.js",
                    requires: [
                        "base-build",
                        "event-base",
                        "spreadsheets",
                        "user-case",
                        "cookie"
                    ]
                }
            }
        },
        "/smst/registration/": {
            combine: true,
            base: "/js/smst/registration/",
            root: "/smst/registration/",
            modules: {
                "registration": {
                    path: "registration.js",
                    requires: [
                        "base-build",
                        "node"
                    ]
                }
            }
        },
        "/smst/smart-form-choice-config/": {
            combine: true,
            base: "/js/smst/smart-form-choice-config/",
            root: "/smst/smart-form-choice-config/",
            modules: {
                "smart-form-choice-config": {
                    path: "smart-form-choice-config.js",
                    "requires": [
                        "base-build",
                        "json-parse",
                        "model",
                        "model-sync-rest",
                        "smart-form"
                    ]
                }
            }
        },
        "/smst/smart-form/": {
            combine: true,
            base: "/js/smst/smart-form/",
            root: "/smst/smart-form/",
            modules: {
                "smart-form": {
                    path: "smart-form.js",
                    "requires": [
                        "autocomplete-plugin",
                        "autocomplete-filters",
                        "base-build",
                        "collection",
                        "io-form",
                        "io-upload-iframe",
                        "node",
                        "substitute",
                        "widget-base",
                        "widget-child",
                        "widget-htmlparser",
                        "widget-parent"
                    ]
                }
            }
        },
        "/smst/smart-formats/": {
            combine: true,
            base: "/js/smst/smart-formats/",
            root: "/smst/smart-formats/",
            modules: {
                "smart-formats": {
                    path: "smart-formats.js",
                    "requires": [
                        "datatype-number",
                        "datatype-date"
                    ]
                }
            }
        },
        "/smst/smart-handlebars-templates/": {
            combine: true,
            base: "/js/smst/smart-handlebars-templates/",
            root: "/smst/smart-handlebars-templates/",
            modules: {
                "smart-handlebars-templates": {
                    path: "smart-handlebars-templates.js",
                    "requires": [
                        "base-build",
                        "handlebars-base",
                        "handlebars-compiler",
                        "io"
                    ]
                }
            }
        },
        "/smst/smart-handlebars-test-loader/": {
            combine: true,
            base: "/js/smst/smart-handlebars-test-loader/",
            root: "/smst/smart-handlebars-test-loader/",
            modules: {
                "smart-handlebars-test-loader": {
                    path: "smart-handlebars-test-loader.js",
                    "requires": [
                        "base-build",
                        "handlebars-base",
                        "handlebars-compiler",
                        "io"
                    ]
                }
            }
        },
        "/smst/smart-routes/": {
            combine: true,
            base: "/js/smst/smart-routes/",
            root: "/smst/smart-routes/",
            modules: {
                "smart-routes": {
                    path: "smart-routes.js",
                    "requires": [
                        "app",
                        "arraysort",
                        "node",
                        "event",
                        "widget",
                        "widget-child",
                        "gallery-google-maps-frame",
                        "datatype-number-format",
                        "datatype-date",
                        "smart-handlebars-templates",
                        "transition"
                    ]
                }
            }
        },
        "/smst/smst-auto-logout/": {
            combine: true,
            base: "/js/smst/smst-auto-logout/",
            root: "/smst/smst-auto-logout/",
            modules: {
                "smst-auto-logout": {
                    path: "smst-auto-logout.js",
                    requires: [
                        "base-build",
                        "gallery-idletimer",
                        "gallery-overlay-extras",
                        "gorilla-overlay-anim-plugin",
                        "node",
                        "overlay"
                    ]
                }
            }
        },
        "/smst/smst-config/": {
            combine: true,
            base: "/js/smst/smst-config/",
            root: "/smst/smst-config/",
            modules: {
                "smst-config": {
                    path: "smst-config.js",
                    requires: [
                        "io",
                        "json-parse"
                    ]
                }
            }
        },
        "/smst/smst-lang/": {
            combine: true,
            base: "/js/smst/smst-lang/",
            root: "/smst/smst-lang/",
            modules: {
                "smst-lang": {
                    path: "smst-lang.js",
                    requires: []
                }
            }
        },
        "/smst/smst-overlays/": {
            combine: true,
            base: "/js/smst/smst-overlays/",
            root: "/smst/smst-overlays/",
            modules: {
                "smst-overlays": {
                    path: "smst-overlays.js",
                    requires: [
                        "gallery-overlay-extras",
                        "gorilla-overlay-anim-plugin",
                        "gorilla-widget-escape-hide-plugin",
                        "gorilla-widget-x-button-plugin",
                        "overlay"
                    ]
                }
            }
        },
        "/smst/smst-session-status/": {
            combine: true,
            base: "/js/smst/smst-session-status/",
            root: "/smst/smst-session-status/",
            modules: {
                "smst-session-status": {
                    path: "smst-session-status.js",
                    requires: [
                        "base-build",
                        "gallery-idletimer",
                        "gallery-stalker",
                        "handlebars-view",
                        "io-base",
                        "json-stringify",
                        "model",
                        "model-sync-rest",
                        "node"
                    ]
                }
            }
        },
        "/smst/smst-utils/": {
            combine: true,
            base: "/js/smst/smst-utils/",
            root: "/smst/smst-utils/",
            modules: {
                "smst-utils": {
                    path: "smst-utils.js",
                    requires: ["io-base"]
                }
            }
        },
        "/smst/smst/": {
            combine: true,
            base: "/js/smst/smst/",
            root: "/smst/smst/",
            modules: {
                "smst": {
                    path: "smst.js",
                    requires: [
                        "_google-analytics-repository",
                        "app",
                        "app-header",
                        "app-help",
                        "app-overlays",
                        "base-build",
                        "case-manager",
                        "case-editor",
                        "console-fix",
                        "datatype-number-parse",
                        "event",
                        "event-tap",
                        "event-custom",
                        "header-views",
                        "querystring-stringify",
                        "mobile-transformer",
                        "smst-config",
                        "smst-lang",
                        "smst-overlays",
                        "smst-session-status",
                        "transition"
                    ]
                }
            }
        },
        "/smst/spreadsheet-definitions/": {
            combine: true,
            base: "/js/smst/spreadsheet-definitions/",
            root: "/smst/spreadsheet-definitions/",
            modules: {
                "spreadsheet-definitions": {
                    path: "spreadsheet-definitions.js",
                    requires: [
                        "base-build",
                        "recordset-base",
                        "io-base",
                        "json"
                    ],
                    skinnable: false
                }
            }
        },
        "/smst/spreadsheet-editor/": {
            combine: true,
            base: "/js/smst/spreadsheet-editor/",
            root: "/smst/spreadsheet-editor/",
            modules: {
                "spreadsheet-editor": {
                    path: "spreadsheet-editor.js",
                    "requires": [
                        "event-key",
                        "smart-form",
                        "smart-form-choice-config",
                        "smart-formats",
                        "node-event-simulate"
                    ]
                }
            }
        },
        "/smst/spreadsheet-footer/": {
            combine: true,
            base: "/js/smst/spreadsheet-footer/",
            root: "/smst/spreadsheet-footer/",
            modules: {
                "spreadsheet-footer": {
                    path: "spreadsheet-footer.js",
                    "requires": ["view"]
                }
            }
        },
        "/smst/spreadsheet-help/": {
            combine: true,
            base: "/js/smst/spreadsheet-help/",
            root: "/smst/spreadsheet-help/",
            modules: {
                "spreadsheet-help": {
                    path: "spreadsheet-help.js",
                    "requires": [
                        "base",
                        "event-focus",
                        "event-mouseenter",
                        "node"
                    ]
                }
            }
        },
        "/smst/spreadsheet-overlay/": {
            combine: true,
            base: "/js/smst/spreadsheet-overlay/",
            root: "/smst/spreadsheet-overlay/",
            modules: {
                "spreadsheet-overlay": {
                    path: "spreadsheet-overlay.js",
                    requires: [
                        "app-overlays",
                        "base-build",
                        "node",
                        "overlay",
                        "dd-drag",
                        "event-outside",
                        "gallery-overlay-extras",
                        "gorilla-widget-x-button-plugin",
                        "gorilla-widget-cancel-button-plugin"
                    ]
                }
            }
        },
        "/smst/spreadsheet-scroll/": {
            combine: true,
            base: "/js/smst/spreadsheet-scroll/",
            root: "/smst/spreadsheet-scroll/",
            modules: {
                "spreadsheet-scroll": {
                    path: "spreadsheet-scroll.js",
                    "requires": [
                        "datatable-base",
                        "datatable-column-widths",
                        "dom-screen"
                    ],
                    "skinnable": true
                }
            }
        },
        "/smst/spreadsheet/": {
            combine: true,
            base: "/js/smst/spreadsheet/",
            root: "/smst/spreadsheet/",
            modules: {
                "spreadsheet": {
                    path: "spreadsheet.js",
                    "requires": [
                        "datatable-base",
                        "datatable-column-widths",
                        "datatable-mutable",
                        "datatable-scroll",
                        "datatype-date",
                        "datatype-number",
                        "smart-formats",
                        "node",
                        "recordset-base",
                        "smart-form-choice-config",
                        "spreadsheet-editor",
                        "spreadsheet-footer",
                        "spreadsheet-help",
                        "spreadsheet-scroll",
                        "smst-utils"
                    ]
                }
            }
        },
        "/smst/spreadsheets/": {
            combine: true,
            base: "/js/smst/spreadsheets/",
            root: "/smst/spreadsheets/",
            modules: {
                "spreadsheets": {
                    path: "spreadsheets.js",
                    requires: [
                        "base-build",
                        "event-base",
                        "event-custom",
                        "interview-ref-field-plugin",
                        "node",
                        "spreadsheet",
                        "spreadsheet-definitions",
                        "spreadsheet-overlay",
                        "user-case"
                    ],
                    skinnable: false
                }
            }
        },
        "/smst/tool-routes-panel/": {
            combine: true,
            base: "/js/smst/tool-routes-panel/",
            root: "/smst/tool-routes-panel/",
            modules: {
                "tool-routes-panel": {
                    path: "tool-routes-panel.js",
                    requires: [
                        "gallery-overlay-extras",
                        "panel",
                        "smart-routes",
                        "widget-parent"
                    ]
                }
            }
        },
        "/smst/user-case/": {
            combine: true,
            base: "/js/smst/user-case/",
            root: "/smst/user-case/",
            modules: {
                "user-case": {
                    path: "user-case.js",
                    requires: [
                        "base-build",
                        "io-base",
                        "json",
                        "node",
                        "plugin",
                        "recordset-base",
                        "recordset-sort",
                        "recordset-indexer"
                    ],
                    skinnable: false
                }
            }
        },
        "gallery": {
            combine: true,
            "root": "/gallery/",
            "base": "/js/gallery/",
            "modules": {
                "gallery-idletimer": {
                    "path": "gallery-idletimer/gallery-idletimer-min.js",
                    "requires": [
                        "event",
                        "event-custom"
                    ]
                },
                "gallery-form": {
                    "path": "gallery-form/gallery-form-min.js",
                    "requires": [
                        "node",
                        "widget-base",
                        "widget-htmlparser",
                        "io-form",
                        "widget-parent",
                        "widget-child",
                        "base-build",
                        "substitute",
                        "io-upload-iframe"
                    ]
                },
                "gallery-outside-events": {
                    "path": "gallery-outside-events/gallery-outside-events-min.js",
                    "requires": [
                        "event-focus",
                        "event-synthetic"
                    ]
                },
                "gallery-overlay-extras": {
                    "path": "gallery-overlay-extras/gallery-overlay-extras-min.js",
                    "requires": [
                        "overlay",
                        "plugin",
                        "event-resize",
                        "gallery-outside-events"
                    ],
                    "supersedes": ["gallery-overlay-modal"]
                },
                "gallery-port": {
                    "path": "gallery-port/gallery-port-min.js",
                    "requires": [
                        "node",
                        "base"
                    ]
                },
                "gallery-treeview": {
                    "path": "gallery-treeview/gallery-treeview-min.js",
                    "requires": [
                        "node",
                        "gallery-port"
                    ]
                },
                "gallery-stalker": {
                    "path": "gallery-stalker/gallery-stalker-min.js",
                    "requires": [
                        "plugin",
                        "node-base",
                        "event-resize",
                        "event-base",
                        "dom-screen",
                        "classnamemanager"
                    ]
                },
                "gallery-google-maps-loader": {
                    "path": "gallery-google-maps-loader/gallery-google-maps-loader-min.js",
                    "requires": [
                        "base",
                        "jsonp"
                    ]
                },
                "gallery-google-maps-frame": {
                    "path": "gallery-google-maps-frame/gallery-google-maps-frame-min.js",
                    "requires": [
                        "base",
                        "frame",
                        "gallery-google-maps-loader"
                    ]
                }
            }
        },
        "yui2": {
            combine: true,
            comboBase: "/js/combo?",
            root: "/2in3/2.8.1/build/",
            base: "/js/2in3/2.8.1/build/",
            patterns: {
                "yui2-": {
                    configFn: function (me) {
                        if (/-skin|reset|fonts|grids|base/.test(me.name)) {
                            me.type = "css";
                            me.path = me.path.replace(/\.js/, ".css");
                            me.path = me.path.replace(/\/yui2-skin/, "/assets/skins/sam/yui2-skin");
                        }
                    }
                }
            }
        }
    }
};