
import { Component, Vue } from 'vue-property-decorator'
import FilingTemplate from '@/components/checkout/filing/FilingTemplate.vue'

@Component({ components: { FilingTemplate } })
export default class FilingNotAvailable extends Vue {
  private cardTitle: string | undefined
  private cardSubTitle: string | undefined
  private cardContent: string | undefined

  created() {
    this.cardTitle = 'Abgabe nicht verfügbar'
    this.cardSubTitle = 'Verfügbar ab Januar ' + (this.$store.state.elsterFilingData.elsterAvailableFrom)
    this.cardContent = 'Die Möglichkeit zur Übermittlung für das Steuerjahr ' + this.$store.state.elsterFilingData.vzYear + ' wird am ' +
      '1. Januar ' + (this.$store.state.elsterFilingData.elsterAvailableFrom) + ' von der Finanzverwaltung freigeschaltet. ' +
      'Hab bis dahin also noch ein wenig Geduld.'
  }
}
