
import { Component, Prop, Vue } from 'vue-property-decorator'
import CheckoutAppHeader from '@/components/checkout/CheckoutAppHeader.vue'
import CheckoutAppError from '@/components/checkout/CheckoutAppError.vue'
import CheckoutBody from '@/components/checkout/base/CheckoutBody.vue'
import NavigationButtons from '@/components/checkout/base/NavigationButtons.vue'
import FinApiWizardComponent from '@/components/checkout/verification/finApi/FinApiWizardComponent.vue'
import { CheckoutStep } from '@/components/checkout/types'
import LoadingComponent from '@/components/checkout/base/LoadingComponent.vue'
import { mapActions, mapGetters } from 'vuex'

@Component({
  components: {
    CheckoutAppHeader,
    FinApiWizardComponent,
    CheckoutBody,
    NavigationButtons,
    LoadingComponent,
    CheckoutAppError
  }
})
export default class CheckOutApp extends Vue {
  @Prop({ default: () => [] }) private components: any | undefined
  @Prop({ default: () => [] }) private verified: boolean | undefined
  @Prop({ default: () => [] }) private finApiEnabled: boolean | undefined
  @Prop({ default: () => [] }) private webidServiceUrl: any | undefined
  @Prop({ default: () => [] }) private vzYear: string | undefined
  @Prop({ default: () => [] }) private elsterAvailableFrom: string | undefined
  @Prop({ default: () => [] }) private checkoutServiceBaseUri: string | undefined

  @Prop({ default: () => [] }) private yuiSections: any | undefined
  @Prop({ default: false }) private readonly boUser!: boolean

  private componentsToRender: any[] = []

  methods() {
    mapActions([
      'updatePaymentData',
      'updateElsterFilingData',
      'updateCheckoutData',
      'updateCheckoutServiceBaseUri',
      'updateVerificationData',
      'updateCheckoutSteps',
      'updateCurrentStep',
      'updateCheckoutComponents',
      'updateYuiSections',
      'resetCheckoutData'
    ])
    mapGetters({
      getCheckoutData: 'getCheckoutData',
      getCurrentFilingStepIndex: 'getCurrentFilingStepIndex'
    })
  }

  mounted() {
    this.$store.dispatch('updateYuiSections', this.yuiSections)
    this.$store.dispatch('resetCheckoutData')
    this.$store.commit('setBoUser', this.boUser)
  }

  async created() {
    this.prepareAndStoreCheckoutSteps()

    this.storePaymentData()
    this.storeElsterFilingData()

    this.storeVerificationData()

    this.storeCheckoutServiceBaseUri()

    await this.storeCheckoutData()
  }

  async storeCheckoutData() {
    await this.$store.dispatch('updateCheckoutData')
  }

  storeCheckoutServiceBaseUri() {
    this.$store.dispatch(
      'updateCheckoutServiceBaseUri',
      this.checkoutServiceBaseUri
    )
  }

  storePaymentData() {
    const paymentData = {
      vzYear: this.vzYear,
      verification: { isVerified: this.verified, verificationMethod: undefined }
    }
    this.$store.dispatch('updatePaymentData', paymentData)
  }

  storeElsterFilingData() {
    const elsterFilingData = {
      vzYear: this.vzYear,
      elsterAvailableFrom: this.elsterAvailableFrom
    }
    this.$store.dispatch('updateElsterFilingData', elsterFilingData)
  }

  storeVerificationData() {
    const chosenVerificationMethod = 'finApi'
    const verificationMethods = [
      {
        name: 'finApi',
        enabled: this.finApiEnabled,
        url: ''
      },
      {
        name: 'webId',
        enabled: true,
        url: this.webidServiceUrl
      }
    ]
    this.$store.dispatch('updateVerificationData', {
      verified: this.verified,
      verificationMethods: verificationMethods,
      chosenVerificationMethod: chosenVerificationMethod
    })
  }

  prepareAndStoreCheckoutSteps() {
    const steps: CheckoutStep[] = []
    let index = 0

    this.componentsToRender = this.components
    if (this.components.length === 1 || this.components.length === 2) {
      index = 1
    }

    this.componentsToRender.forEach((componentName: string) => {
      const step: CheckoutStep = {
        stepNumber: index,
        stepName: componentName,
        stepEventName: componentName,
        completed: false,
        subSteps: []
      }

      if (componentName === 'checkout-web-component') {
        // Substeps from checkout-web-component
        const subSteps = [
          'Adresse',
          'Zahlungsart',
          'Bestätigen',
          'Bezahlen'
        ]
        const subStepsTranslation = [
          'customer-editor',
          'payment-method-selector',
          'confirmation',
          'payment'
        ]
        step.subSteps = subSteps.map(subStep => {
          return {
            stepNumber: index++,
            stepName: subStep,
            stepEventName: subStepsTranslation[index],
            completed: false
          }
        })
      } else {
        if (componentName === 'verification-overview') {
          step.stepName = 'Verifikation'
        } else if (componentName === 'checkout-filing') {
          step.stepName = 'Abgabe'
        }

        index++
      }
      if (componentName !== 'payment-overview') {
        steps.push(step)
      }
    })

    this.$store.dispatch('updateCheckoutSteps', steps)
    this.$store.dispatch('updateCheckoutComponents', this.componentsToRender)
  }
}
