YUI.add('case-editor-sidebar-navigator', function (Y) {
    'use strict'

    var Gallery = Y.apm,
        Slang = Y.smst.Lang

    Y.CaseEditorSidebarNavigator = Y.Base.create('CaseEditorSidebarNavigator', Y.View, [], {
        tree: null,
        formNodes: null,
        navigationData: null,
        flatNavigationData: null,
        customSections: [],
        _eventHandlers: [],
        // we have to skip the very first call!
        _isReady: false,

        _interviewBaseUrl: null,
        _sections: null,

        //click neben dem Link bei items mit Icon hat sonst href #
        events: {
            '.ygtvspacer': {
                click: function (e) {
                    e.halt()
                },
            },
        },

        initializer: function () {
            var that = this

            that._eventHandlers.push(
                Y.after('CaseEditor:pageStateChange', that.updateNavigationAfterStateChange, that))
            that._eventHandlers.push(Y.after('CaseEditor:activeSectionChange', that._afterActiveSectionChange, that))

            that._eventHandlers.push(Y.after('*:filingStateChange', that._afterFilingStateChange, that))
            that._eventHandlers.push(Y.after('*:sectionStateChanged', that.updateSectionStates, that))

            that._eventHandlers.push(Y.after('*:smartCheckActive', that._highlightErrors, that))

            that._sections = Y.smst.CaseEditor.SECTIONS
        },

        render: function () {
            // this will create our container.
            var container = this.get('container')

            container.addClass('case-editor-sidebar-navigator-container')
            container.removeClass('ui-display-none')

            return this
        },

        destructor: function () {
            Slang.detachEventListener(this._eventHandlers)
            Slang.detachEventListener(this._expandHandler)
        },

        findAndOpenNode: function (path) {
            var activeTreeNode,
                activeSection
            if (this.tree) {
                activeTreeNode = this.tree.getNodeByProperty('href', path)

                // This will find the active Area if any interview node is active. For the other sections
                // this will not work.
                if (activeTreeNode) {
                    activeSection = new Y.Section({
                        active: true,
                        treeNode: activeTreeNode,
                        link: path,
                        label: activeTreeNode.label,
                        name: activeTreeNode.label,
                    })
                    this.fire('newSubSection', {section: activeSection})
                }
            }
        },

        // This method is called whenever the pageInfo of the editor changes
        updateNavigationAfterStateChange: function (e) {
            var state, activeArea, activeAreaLink,
                container = this.get('container')

            Y.log('Updating navigation after state change', 'DEBUG', 'CaseEditorSidebarNavigator')

            state = e.newVal

            //after a click on the tree or a next which doesn't need a submit we skip this
            if (!(('navigation' === e.src || 'next' === e.src) && !state.caseChanged)) {
                // We have to get the tree from the server…
                Y.log('Updating tree using render', 'DEBUG', 'CaseEditorSidebarNavigator')
                this._interviewBaseUrl = this._section('interview').get('link')

                // clean up if needed
                if (null != this.tree) {
                    try {
                        this.tree.destroy()
                    } catch (err) {
                        // wir gehen davon aus, das jetzt sowieso alles zu spät ist. Hier gibt es nichts mehr zu tun.
                    }
                }

                // chrome will remove the node when destroying the tree - FF won't.
                if (!container.one('#ui-nav-tree')) {
                    container.append(Y.Node.create('<div id="ui-nav-tree" ' +
                        'class="case-editor-sidebar-navigator-tree-container whitebg ygtv-highlight" />'))
                }
                this.tree = new Gallery.TreeView('ui-nav-tree')
                this.tree.singleNodeHighlight = true

                this.loadTree(state)

                Y.log('Updating tree using render: FINISHED', 'DEBUG', 'CaseEditorSidebarNavigator')
            }

            Y.log('Section: ' + this._sections.getActive().get('name'))

            //the state area is only correct while being in the interview
            if ('interview' === this._sections.getActive().get('name')) {
                Y.log('Updating tree using highlighting', 'DEBUG', 'CaseEditorSidebarNavigator')

                // We have to mimic an area config and create the href (via _createLink) to find the right tree node.
                activeArea = {
                    id: state.area,
                    formId: state.formId,
                    formIndex: state.formIndex,
                }
                if (state.multiFieldIndex !== -1) {
                    activeArea.multiFieldIndex = state.multiFieldIndex
                }
                activeArea.multiFieldBase = state.isMultiFieldBase

                if (state.isMultiFormBase) {
                    activeArea.type = 'multiFormBase'
                }
                // sanity checking for the tree
                if (this.tree) {
                    activeAreaLink = this._createLink(activeArea)

                    Y.currentActiveAreaLink = activeAreaLink

                    this.findAndOpenNode(activeAreaLink)
                    this._doHighlightAnimation()
                }
            }
        },

        _doHighlightAnimation: function () {
            var activeLabelNode = Y.one('.ygtv-highlight1 .openArea')

            /*
             * This will make the node glow for a second.
             */
            if (activeLabelNode) {
                if (this._glowTimer) {
                    this._glowTimer.cancel()
                    this._glowTimerNode.setStyle('backgroundColor', null)
                    this._glowTimerNode.setStyle('color', null)
                }

                this._glowTimerNode = activeLabelNode
                this._glowTimer = Y.later(100, activeLabelNode, function () {
                    this.transition({
                        duration: 0,
                        backgroundColor: '#5F6FFF',
                        color: '#fff',
                    }, function () {
                        this.transition({
                            delay: 0.2,
                            duration: 0,
                            backgroundColor: '#fff',
                            color: '#5F6FFF',
                        }, function () {
                            this.transition({
                                delay: 0.2,
                                duration: 0,
                                backgroundColor: '#5F6FFF',
                                color: '#fff',
                            }, function () {
                                this.transition({
                                    delay: 0.2,
                                    duration: 0,
                                    backgroundColor: '#fff',
                                    color: '#5F6FFF',
                                }, function () {
                                    // clean up
                                    this.setStyle('backgroundColor', null)
                                    this.setStyle('color', null)
                                })
                            })
                        })
                    })
                })
            }
        },

        _expandToNode: function (activeNode) {
            Y.log('Expand to node', 'DEBUG', 'CaseEditorSidebarNavigator')

            if (activeNode) {
                if (this.tree.currentFocus) {
                    this.tree.currentFocus._removeFocus()
                }

                var expandParent = function (node) {
                    if (node.parent) {
                        expandParent(node.parent)
                        node.parent.expand()
                    }
                }
                expandParent(activeNode)

                if (!activeNode.expanded) {
                    activeNode.expand()
                }

                //expand fully to make euer launcher area visible
                //TODO: make this configurable in the backoffice
                if (activeNode.href.indexOf('/interview/3200/') > 0 || activeNode.href.indexOf('/interview/3201/') > 0) {
                    activeNode.expandAll()
                }

                activeNode.highlight()
            }
        },

        _section: function (name) {
            return this._sections.getByName(name)
        },

        updateSectionStates: function (json) {
            var that = this

            // we don't know which sections get updated here. So we have to test for all possible properties.
            if (json.hasOwnProperty('checkOk')) {
                that._section('summary').set('ok', json.checkOk)
            }
            if (json.hasOwnProperty('assessmentOk')) {
                that._section('assessment').set('ok', json.assessmentOk)
            }
            if (json.hasOwnProperty('analysisOk')) {
                that._section('analysis').set('ok', json.analysisOk)
            }
            if (json.hasOwnProperty('resultsOk')) {
                that._section('taxmeter').set('ok', json.resultsOk)
            }
            if (json.hasOwnProperty('viewed')) {
                that._section('filing2-process-preview').set('ok', json.viewed)
            }
            if (json.hasOwnProperty('notYetSentViaElster')) {
                that._section('filing2-process-filing').set('ok', !json.notYetSentViaElster)
            }
            if (json.hasOwnProperty('documentsPrinted')) {
                that._section('filing2-process-documents/print').set('ok', json.documentsPrinted)
            }
            if (json.hasOwnProperty('receiptsOk')) {
                that._section('filing2-process-documents/receipts').set('ok', json.receiptsOk)
            }
            if (json.hasOwnProperty('documentsSent')) {
                that._section('filing2-process-documents/send').set('ok', json.documentsSent)
            }

            if (json.hasOwnProperty('everythingOk')) {
                that._section('filing2-process-final').set('ok', json.everythingOk)
                that._section('filing2-process').set('ok', json.everythingOk)
            }
        },

        _highlightTreeArea: function (err, id) {
            var that = this

            if (!id) return

            Y.all('#ui-nav-tree a.openArea').each(function (it2) {
                if (!it2.hasClass('ui-nav-status') && it2.get('href').indexOf(that._createLinkFromError(err, id)) >= 0) {
                    var doHighlight = it2.get('href').indexOf('/false/false', it2.get('href').length - '/false/false'.length) == -1

                    //ONSE-11482 don't highlight if index cannot be determined correctly
                    if (err.forceMultiFieldIndex1) {
                        Y.Array.each(err.forceMultiFieldIndex1, function (it2, idx2) {
                            if (it2) {
                                doHighlight = false
                            }
                        })

                        Y.Array.each(err.forceMultiFormIndex1, function (it2, idx2) {
                            if (it2) {
                                doHighlight = false
                            }
                        })
                    }

                    if (doHighlight) {
                        it2.addClass('ui-nav-status')

                        if (err.type == 'PLAUSIBILITY_HINT') {
                            it2.addClass('nav-recommendation')
                        } else if (err.type == 'PLAUSIBILITY_WARNING') {
                            it2.addClass('nav-warning')
                        } else {
                            it2.addClass('nav-error')
                        }
                    }
                }
            })
        },

        _addSection: function (sectionName, parentNode, label) {
            var that = this,
                section, treeNode, styles = 'openArea'

            section = that._section(sectionName)

            if (section && section.get('visible')) {
                if (true === section.get('ok')) {
                    styles += ' nav-filled'
                }

                treeNode = new Gallery.TextNode(
                    {label: label ? label : section.get('label'), href: section.get('link'), labelStyle: styles},
                    parentNode)
                section.set('treeNode', treeNode)

                this.customSections.push(treeNode)
            }

            return treeNode
        },

        renderNavigation: function (json, state) {
            var rootNode = this.tree.getRoot(),
                activeSection,
                activeInterviewSection,
                filingNode

            Y.log('Rendering tree…', 'DEBUG', 'CaseEditorSidebarNavigator')

            this.navigationData = json.areas
            this._foundActiveNode = false
            this.flatNavigationData = Y.Array([])
            this.formNodes = Y.Array([])
            this.pathFilled = true
            this.customSections = []

            // This will add the whole interview to the tree.
            var areas = json.areas

            if (json.areas[0].label == 'Interview') {
                //gide the top level, but only for est
                areas = json.areas[0].children
            }

            activeInterviewSection = this.addAreas(areas, json, rootNode)

            this._addSection('summary', rootNode)
            this._addSection('assessment', rootNode)
            this._addSection('taxmeter', rootNode)
            this._addSection('analysis', rootNode)

            if (!state.parentSessionURL) {
                filingNode = this._addSection('filing2-process', rootNode)
                if (filingNode) {
                    this._addSection('filing2-process-preview', filingNode)

                    this._addSection('filing2-process-filing', filingNode)
                    this._addSection('filing2-process-documents/print', filingNode)
                    this._addSection('filing2-process-documents/receipts', filingNode)
                    this._addSection('filing2-process-documents/send', filingNode)

                    this._addSection('filing2-process-final', filingNode)
                }

                if (Y._currentState.transferDecreeData || Y._currentState.transferDecreeData2) {
                    var decreeNode = this._addSection('decree', rootNode, Y._currentState.hasSeparateAssessment ? 'Steuerbescheide' : null)
                    if (decreeNode) {
                        if (Y._currentState.hasSeparateAssessment && Y._currentState.transferDecreeData2) {
                            this._addSection('decree-decree-mc', decreeNode, 'Einkommensteuer ' + Y._currentState.maleName)
                            this._addSection('decree-decree-fc', decreeNode, 'Einkommensteuer ' + Y._currentState.femaleName)
                        } else {
                            this._addSection('decree-decree-mc', decreeNode)
                        }

                        this._addSection('decree-decree-objection', decreeNode)
                    }
                }
            } else {
                var rn = rootNode.children[0]
                rn.label = 'Weiter zur Einkommensteuer'
                rn.href = state.parentSessionURL

                this._addSection('back-to-est', rootNode)
            }

            this.tree.render()

            if (this._expandHandler) {
                Slang.detachEventListener(this._expandHandler)
            }
            this._expandHandler = this.tree.on('expandComplete', this._highlightErrors, this)

            // because of the fact that we use two systems to find the currently active node we have to implement a
            // "special treatment" here. If the active section is the interview - we will do nothing as it already was
            // activated before. (ONSE-8752, ONSE-8800 and ONSE-8801)
            activeSection = this._sections.getActive()
            if ('interview' === activeSection.get('name')) {
                // we won't use the top level section here but the active interview area.
                activeSection = activeInterviewSection
            }

            this.fire('newSubSection', {section: activeSection})
        },

        _highlightErrors: function () {
            if (Y.globalErrors && Y.smartCheckMode) {
                Y.one('#ui-nav-tree').all('a.ui-nav-status').each(function (it) {
                    it.removeClass('ui-nav-status')
                    it.removeClass('nav-error')
                    it.removeClass('nav-recommendation')
                    it.removeClass('nav-warning')
                })

                Y.Array.each(Y.globalErrors, function (it, idx) {
                    this._highlightTreeArea(it, it.areaId)

                    if (it.areaIds) {
                        Y.Array.each(it.areaIds, function (it2, idx2) {
                            this._highlightTreeArea(it, it2)
                        }, this)
                    }
                }, this)
            }
        },

        _afterActiveSectionChange: function (e) {
            var that = this,
                section = e.newVal

            if (section) {
                // we have one special case to look after here.
                // if the new section is the checkout process we have to activate the section called
                // 'filing2-process-filing' because checkout is a subsection of this one and comes without an tree
                // entry.
                if ('checkout-process' === section.get('name')) {
                    section = that._section('filing2-process-filing')
                    section.set('active', true)
                }
                that._expandToNode(section.get('treeNode'))
            }
        },

        _afterFilingStateChange: function (e) {
            this.updateSectionStates(e.newVal)
        },

        areaHasErrors: function (area) {
            if (area == null) {
                Y.error('nav-area is null!')
            }

            if (area.errors != 0) {
                return true
            }
            if (area.hasChildren) {
                for (var i = 0; i < area.children.length; i++) {
                    if (this.areaHasErrors(area.children[i])) {
                        return true
                    }
                }
            }

            return false
        },

        subAreasFilled: function (area) {
            if ((area.isEmpty && area.hasChildren) || area.filled) {
                if (area.hasChildren) {
                    for (var i = 0; i < area.children.length; i++) {
                        if (!this.subAreasFilled(area.children[i])) {
                            return false
                        }
                    }
                }
            } else {
                return false
            }

            return true
        },

        oneSubAreaFilled: function (area) {
            if (area.filled) {
                return true
            }
            if (area.hasChildren) {
                for (var i = 0; i < area.children.length; i++) {
                    if (this.oneSubAreaFilled(area.children[i])) {
                        return true
                    }
                }
            }

            return false
        },

        addAreas: function (areas, json, parent) {
            var that = this,
                activeInterviewSection = null,
                activeChildSection = null

            for (var i = 0; i < areas.length; i++) {
                var area = areas[i],
                    style = 'openArea',
                    subAreasFilled,
                    treeNodeProperties, treeNode

                that.flatNavigationData.push(area)

                subAreasFilled = that.subAreasFilled(area)
                if (area.isSummary) {
                    if (area.filled) {
                        style += ' nav-summary-filled'
                    } else {
                        style += ' nav-summary nav-unfilled'
                    }
                } else if (area.type == 'multiFieldBase' || area.type == 'multiFormBase') {
                    if (area.filled && subAreasFilled) {
                        style += ' nav-overview-filled'
                    } else if (area.filled || that.oneSubAreaFilled(area)) {
                        style += ' nav-overview-semifilled'
                    } else {
                        style += ' nav-overview nav-unfilled'
                    }
                } else if ((area.filled || area.isEmpty) && subAreasFilled) {
                    style += ' nav-filled'
                } else if (area.filled || that.oneSubAreaFilled(area)) {
                    style += ' nav-semifilled'
                } else if (!area.filled) {
                    style += ' nav-unfilled'
                }

                var escapedLabel = Y.smst.Utils.textAreaEscape(area.label)

                area.label = escapedLabel

                treeNodeProperties = {
                    label: Y.Escape.html(area.label),
                    href: that._createLink(area),
                    labelStyle: style,
                }

                treeNode = new Gallery.TextNode(treeNodeProperties, parent, area.expanded)

                if (area.active) {
                    activeInterviewSection = new Y.Section({
                        active: true,
                        treeNode: treeNode,
                        link: treeNodeProperties.href,
                        label: treeNodeProperties.label,
                        name: treeNodeProperties.label,
                    })
                    that._foundActiveNode = true
                } else if (!that._foundActiveNode) {
                    that.pathFilled = that.pathFilled && (area.filled || area.isEmpty)
                }

                if (area.hasChildren) {
                    activeChildSection = that.addAreas(area.children, json, treeNode)
                }
            }
            return activeInterviewSection ? activeInterviewSection : activeChildSection
        },

        _createLink: function (area) {
            return this._interviewBaseUrl +
                '/' + area.id +
                '/' + area.formId +
                '/' + area.formIndex +
                '/' + (area.multiFieldIndex != undefined ? area.multiFieldIndex : '-1') +
                '/' + (area.multiFieldIndex != undefined ? 'true' : 'false') +
                '/' + ((area.type != undefined && area.type == 'multiFormBase') ? 'false' : 'true')
        },

        _createLinkFromError: function (err, id) {
            return this._interviewBaseUrl +
                '/' + (id ? id : err.areaId) +
                '/' + err.formId +
                '/' + err.formIndex +
                '/' + (err.index != undefined ? err.index : '-1')
        },

        loadTree: function (state) {
            var that = this

            // We get the tree and some extra navigation data with each request.
            var json = Y.JSON.parse(Y.io('api/tree', {sync: true}).responseText)
            // first update the state of the sections.
            that.updateSectionStates(json)
            // now render the tree
            that.renderNavigation(json, state)
        },

        _add: function (tree, node) {
            if (!node) {
                return
            }

            tree.expanded = node.expanded
            tree.depth = node.depth
            tree.highlighted = node.highlightState == 1
            tree.href = node.href
            tree.label = node.label
            tree.classes = node.labelStyle
            tree.children = []

            Y.Array.each(node.children, function (it, idx) {
                tree.children[idx] = this._add({}, it)
            }, this)

            return tree
        },
    }, {
        ATTRS: {
        },
    })
}, '1.0.0', {
    requires: [
        'base-build',
        'gallery-port',
        'gallery-treeview',
        'json',
        'model',
        'node',
        'smst-lang',
        'smst-utils',
        'transition',
        'view',
    ],
})
